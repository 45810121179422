import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
// import "./_style.scss";
import '../email/email.css';
import'../../App.css';
import './oc.css';
import logo from '../../zayogro.png';
// import AuthService from "../components/details/AuthService";
import axios from'axios';
// import CookieService from "./CookieService";
import { Col, Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { Container, Row, Nav, NavItem, Navbar,Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,ModalTitle,Accordion,Card,Alert } from "react-bootstrap";
import {Tab,Tabs,T} from 'react-bootstrap-tabs'; 
import Highcharts from 'highcharts';
import sankey from "highcharts/modules/sankey.js";
import organization from "highcharts/modules/organization.js";
import HighchartsReact from 'highcharts-react-official';  

sankey(Highcharts);
organization(Highcharts);
const usid = localStorage.getItem('uname1')+' '+localStorage.getItem('uname3');
const options = {
  credits:{
    enabled:false,
   },
  title: {
    text: null
  },
  chart: {
    height: 900,
    inverted: true
  },
  title: {
    // useHTML: true,
    text: 'Organization chart'
  },
  accessibility: {
    point: {
      descriptionFormatter: function(point) {
        var nodeName = point.toNode.name,
          nodeId = point.toNode.id,
          nodeDesc = nodeName === nodeId ? nodeName : nodeName + ', ' + nodeId,
          parentDesc = point.fromNode.id;
        return point.index + '. ' + nodeDesc + ', reports to ' + parentDesc + '.';
      }
    }
  },
  series: [{
    type: 'organization',
    name: 'YUGOS',
    keys: ['from', 'to'],
    data: [
      ['Chairman', 'MD'],
      ['MD', 'CEO'],
      ['CEO', 'VP'],
      ['CEO', 'SR-DR'],
      ['CEO', 'VP1'],
      ['CEO', 'VP2'],
      ['CEO', 'SR_DR2'],
      ['CEO','AVP'],
      ['CEO','SR-DR3'],
      ['CEO','CIO'],
      ['SR_DR2','AVP1'],
      ['AVP1','ES-N'],
      ['AVP1','ES-S'],
      ['AVP1','ES-EC'],
      ['AVP1','ES-W'],
      ['AVP1','ES-SM'],
      ['AVP1','ES-MRA'],
      ['AVP1','HRBP'],
      ['ES-N','SN1'],
      ['ES-N','SN2'],
      ['ES-N','SN3'],
      ['ES-N','SN4'],
      ['ES-N','SN5'],
      ['ES-S','SS1'],
      ['ES-S','SS2'],
      ['ES-S','SS3'],
      ['ES-S','SS4'],
      ['ES-EC','EC1'],
      ['ES-EC','EC2'],
      ['ES-EC','EC3'],
      ['ES-EC','EC4'],
      ['ES-EC','EC5'],
      ['ES-W','SW1'],
      ['ES-W','SW2'],
      ['ES-W','SW3'],
      ['ES-W','SW4'],
      ['ES-W','SW5'],
      ['ES-SM','SM1'],
      ['ES-SM','SM2'],
      ['ES-MRA','MRA1'],
      ['ES-MRA','MRA2'],
      ['ES-MRA','MRA3']
    ],
    levels: [{
      level: 0,
      color: 'silver',
      dataLabels: {
        color: 'black'
      },
      height: 25
    }, {
      level: 1,
      color: 'silver',
      dataLabels: {
        color: 'black'
      },
      height: 25
    }, {
      level: 2,
      color: '#980104',
      height: 25
    }, {
      level: 4,
      color: '#359154',
      height: 25
    }, {
      level: 5,
      color: '#95B9C7',
      height: 25
    },{
      level: 6,
      color: '#C47451',
      height: 25
    },{
      level: 7,
      color: '#C47451',
      height: 25
    },{
      level: 8,
      color: '#C47451',
      height: 25
    },{
      level: 9,
      color: '#C47451',
      height: 25
    }],
    nodes: [{
      id: 'Chairman',
      title: 'Chairman',
      name: 'James Mahalanobis',
    }, {
      id: 'MD',
      title: 'MD',
      name: 'Balu Mahendru',
    }, {
      id: 'CEO',
      title: 'CEO',
      name: 'Sumit Srivastava',
     
    }, {
      id: 'VP',
      title: 'VP - HR',
      name: 'Alok Sharma',
      color: '#007ad0',
     
    }, {
      id: 'SR-DR',
      title: 'Sr. Dir - Corp Banking',
      name: 'Gourav Goel',
      
    }, {
      id: 'VP1',
      title: 'VP - Legal',
      name: 'Bijoy Sen',
      
    }, {
      id: 'VP2',
      title: 'VP-Treasury & Trade Solutions',
      name: 'Dan Gupta',
      
    }, {
      id: 'SR_DR2',
      title: 'Sr.Dir-Markets and Securities',
      name: 'Anand Kumar'

    }, {
      id: 'AVP',
      title: 'AVP - Legal',
      name: 'Paddu Krishnan'

    }, {
      id: 'SR-DR3',
      title: 'Sr.Dir - Compliance',
      name: 'Babu Kalyani'
    }, {
      id: 'CIO',
      title: 'CIO',
      name: 'Mohan Babu'
    },{
      id: 'AVP1',
      title: 'AVP - Equities',
      name: usid
    },{
      id: 'ES-N',
      title: 'Equity Sales - North',
      name: 'Vivek Singh',
      layout: 'hanging',
    },{
      id: 'SN1',
      name: 'Kunal',
    },{
      id: 'SN2',
      name: 'Prateek'
    },{
      id: 'SN3',
      name: 'Rajeev'
    },{
      id: 'SN4',
      name: 'Shantanu'
    },{
      id: 'SN5',
      name: 'Varun'
    },{
      id: 'ES-S',
      title: 'Equity Sales - South',
      name: 'Deepak Kumar',
      layout: 'hanging',
    },{
      id: 'SS1',
      name: 'Amit'
    },{
      id: 'SS2',
      name: 'Rahul'
    },{
      id: 'SS3',
      name: 'Bansi'
    },{
      id: 'SS4',
      name: 'Shalini'
    },{
      id: 'ES-EC',
      title: 'Equity Sales - East and Central',
      name: 'Kuldeep Raj',
      layout: 'hanging',
    },{
      id: 'EC1',
      name: 'Janet'
    },{
      id: 'EC2',
      name: 'Asif'
    },{
      id: 'EC3',
      name: 'Sunder'
    },{
      id: 'EC4',
      name: 'Muskaan'
    },{
      id: 'EC5',
      name: 'Kumar'
    },{
      id: 'ES-W',
      title: 'Equity Sales - West',
      name: 'Dona Negi',
      layout: 'hanging',
    },{
      id: 'SW1',
      name: 'Rohit'
    },{
      id: 'SW2',
      name: 'Vijay'
    },{
      id: 'SW3',
      name: 'Ravi'
    },{
      id: 'SW4',
      name: 'Anubhav'
    },{
      id: 'SW5',
      name: 'Aks'
    },{
      id: 'ES-SM',
      title: 'Equity Sales - Singapore and Malaysia',
      name: 'David Ganesh',
      layout: 'hanging',
    },{
      id: 'SM1',
      name: 'Madhur'
    },{
      id: 'SM2',
      name: 'Sunny'
    },{
      id: 'ES-MRA',
      title: 'Market Research and Analytics',
      name: 'Sreeram V',
      layout: 'hanging',
    },{
      id: 'MRA1',
      name: 'Varun'
    },{
      id: 'MRA2',
      name: 'Pommi',
      // column: 8
    },{
      id: 'MRA3',
      name: 'Christopher',
    },{
      id: 'HRBP',
      title: 'HRBP',
      name: 'Jayesh Singh'
    }],
    colorByPoint: false,
    color: '#007ad0',
    dataLabels: {
      color: 'white',      
    },
    borderColor: 'white',
    nodeWidth: 65,
   
  }],
  tooltip: {
    outside: true
  },

};



class Details extends Component<RouteComponentProps> {
    state = {
        firstname: "",
        middlename: "",
        lastname: "",
        company: "",
        gender: "",
        department: "",
        age: "",
        totalexp: "",
        currentexp: "",
        testpur: "",
        isChecked: false,  
    };
   

    async handleFormSubmit(event) {

        event.preventDefault();

        const postData = {
            // firstname: this.state.firstname,
            // middlename: this.state.middlename,
            // lastname: this.state.lastname,
            company: this.state.company,
            gender: this.state.gender,
            department: this.state.department,
            age: this.state.age,
            totalexp: this.state.totalexp,
            currentexp: this.state.currentexp,
            testpur: this.state.testpur,
        };

        const usid = localStorage.getItem('userid');
        this.props.history.push("/Details");
        // axios.put('http://localhost:8000/api/formSubmit/'+usid, postData)
        // .then(function (response) {

        //     console.log(response.data);

        // })

        // .catch(function (error) {

        //     console.log(error);

        // });
        
        

        
        // const response = await AuthService.doUserLogin(postData);
        // if (response) {
        //     AuthService.handleLoginSuccess(response, this.state.isChecked);
        //     this.props.history.push("/dashboard");
        // } else {
        //     alert("Please check your credentials and try again");
        // }
    }

    handleChecked() {
        this.setState({ isChecked: !this.state.isChecked });
    }

    render() {
        const { firstname, middlename, lastname, company,gender,department,age,totalexp,currentexp,testpur} = this.state;
        const usid = localStorage.getItem('userid');
        return (
            <React.Fragment>
<div className="container-fluid" style={{ backgroundColor: "#FFFFFF" }}>
<Navbar
          className=" navbar-light mt-0"
          // style={{ backgroundColor: "#C0C0C0" }} //blue=#0173c7 ash=#bfc9ca
          // style={{ backgroundColor: "#b8e2f2" }} 
        >
          {/* <img className="login-logo-small" src={logo} alt=""></img> */}

          <Nav>
            <NavItem>
            <img src={logo} className="login-logo-small mt-1 h-5vmin" alt=""></img>
            </NavItem>
          </Nav>
          <Nav className="mr-auto ml-auto">
            <NavItem>
              {/* <Button className="btn btn-primary btn-group btn-sm ml-3 rounded-pill " type="button"  >
                Home
              </Button> */}
            {/* </NavItem>
            <Nav.Link href="/">Home</Nav.Link>
            <NavItem> */}
              {/* <Button className="btn btn-primary btn-group btn-sm ml-3 rounded-pill " type="button" >
            MESSAGE OF CHAIRMAN
              </Button> */}
            {/* </NavItem>
            <NavItem>
              <Button className="btn btn-primary btn-group btn-sm ml-3 rounded-pill " type="button" >
            MARKETS
              </Button>
            </NavItem>
            <NavItem>
              <Button className="btn btn-primary btn-group btn-sm ml-3 rounded-pill " type="button" >
            ORGANIZATION
              </Button>
            </NavItem>
            <NavItem>
              <Button className="btn btn-primary btn-group btn-sm ml-3 rounded-pill " type="button" >
            BALANCE SHEET
              </Button>
            </NavItem>
            <NavItem>
              <Button className="btn btn-primary btn-group btn-sm ml-3 rounded-pill " type="button" >
            BUSINESS CHALLENGES
              </Button>
            </NavItem>
            <NavItem>
              <Button className="btn btn-primary btn-group btn-sm ml-3 rounded-pill " type="button" >
            BUSINESS CHALLENGES
              </Button>
            </NavItem>
            <NavItem>
              <Button className="btn btn-primary btn-group btn-sm ml-3 rounded-pill " type="button" >
            NEWS FEED
              </Button> */}
            </NavItem>
            
          </Nav>
          <Nav>
            <NavItem>
              <p
                style={{
                  fontWeight: "700",
                  fontSize: "1.7rem",
                  color: "white",
                  textShadow: "4px 4px 8px #000000"
                }}
              >
                YUGOS
              </p>
            </NavItem>
          </Nav>
          
          <Nav className="mr-auto ml-auto">
            <NavItem>
              {/* <Button className="btn btn-primary btn-group btn-sm ml-3 rounded-pill " type="button"  >
                Home
              </Button> */}
            {/* </NavItem>
            <Nav.Link href="/">Home</Nav.Link>
            <NavItem> */}
              {/* <Button className="btn btn-primary btn-group btn-sm ml-3 rounded-pill " type="button" >
            MESSAGE OF CHAIRMAN
              </Button> */}
            {/* </NavItem>
            <NavItem>
              <Button className="btn btn-primary btn-group btn-sm ml-3 rounded-pill " type="button" >
            MARKETS
              </Button>
            </NavItem>
            <NavItem>
              <Button className="btn btn-primary btn-group btn-sm ml-3 rounded-pill " type="button" >
            ORGANIZATION
              </Button>
            </NavItem>
            <NavItem>
              <Button className="btn btn-primary btn-group btn-sm ml-3 rounded-pill " type="button" >
            BALANCE SHEET
              </Button>
            </NavItem>
            <NavItem>
              <Button className="btn btn-primary btn-group btn-sm ml-3 rounded-pill " type="button" >
            BUSINESS CHALLENGES
              </Button>
            </NavItem>
            <NavItem>
              <Button className="btn btn-primary btn-group btn-sm ml-3 rounded-pill " type="button" >
            BUSINESS CHALLENGES
              </Button>
            </NavItem>
            <NavItem>
              <Button className="btn btn-primary btn-group btn-sm ml-3 rounded-pill " type="button" >
            NEWS FEED
              </Button> */}
            </NavItem>
            
          </Nav>

          <Nav>
            <NavItem>
              <p
                style={{
                  fontWeight: "700",
                  fontSize: "1.7rem",
                  color: "white",
                  textShadow: "4px 4px 8px #000000"
                }}
              >
                E-Volve
              </p>
            </NavItem>
          </Nav>
        </Navbar>

        <div className="container-fluid">
        <Tabs onSelect={(index, label) => console.log(label + ' selected')}>
    <Tab label="HOME">
      <br></br>
<p  align='justify'>Started by Yugandhar Misr, a banking genius of his time, YUGOS began operations in Bishtupur in 1976. 
  Today YUGOS has emerged to become a significant investor in the Indian financial market.
  As promoter-shareholder, YUGOS has played a leading role in establishing important market intermediaries such as depositories, credit bureau, clearing and payment institutions. </p>

<p  align='justify'>YUGOS has always believed in self sufficiency towards which end, it also has established YUGOS  Software Ltd. and SOG Solutions Ltd.
   YUGOS is deeply committed to environmental safety and “Going Green”.
   Towards that, it has added two more green certified buildings to its office premises in 2015, and in 2017 moved its headquarters to “The Starhub”, a world-class environmentally friendly building. </p>

<p  align='justify'>Today YUGOS employees number over 100,000 and come from over 85 countries around the world.
   With over 800 branches across 50 countries, YUGOS is truly a global presence in the global financial sector.
  In addition, its network of YUGOS Solution Centres have worked as leading off-shoring units for YUGOS group globally enabling YUGOS to take advantage of flexibility and unprecedented economies of scale while providing employment opportunities in the areas of technology, processing, analytics and financial processes.</p>

 <p  align='justify'>Mr. Misr passed on in 2019 at the age of 80 and the mantle of Chairmanship has passed on to his son-in-law James Mahalanobis.
    A graduate of the prestigious Omnion College, James has worked with various brands like Country Bank and Katok Financials before joining YUGOS India and rising up through the ranks.
    Starting as a Relationship Manager, he moved to London in 1999 as a Director for Emerging Markets Structuring and Syndications.
    He has worked across a number of management roles across YUGOS. </p>
 
 <p  align='justify'>He then went on to head Corporate Banking for Central Eastern Europe, Middle East & Africa (CEEMEA) where he managed a team of 250 bankers.
    Prior to his current role, he was Head of Capital Markets Origination (CMO) business for Asia Pacific, based in Singapore and was responsible for origination of YUGOS’s Capital Markets activities across Equity, Bonds, Loans and Structured Financing. 
   Between 2013 and 2019, James was also the Co-Head for EMEA Loans, Capital Markets Origination, with oversight of the Project and Infrastructure Finance business.</p>
 
 <p  align='justify'>James is a member of the Managing Committee at the Indian Banks Association (IBA) and National Executive Committee at Federation of Indian Chambers of commerce and Industry (FICCI). 
   James is also on the Global Board of Directors at US India Business Council (USIBC).</p>
 </Tab>

<Tab label="MESSAGE OF CHAIRMAN">
  <br></br>
  <p>Dear Fellow Shareholders:</p>
      <p align='justify'>This year, YUGOS delivered our most profitable year since 2010.
         The USD 10.3 billion we earned on USD 60.3 billion in revenues was USD 1 billion higher than in 2020.
         Our earnings per share of USD 8.04 were up more than 20% compared with the year before.
         We drove 4% underlying revenue growth across our Consumer and Institutional franchises.
         We have grown loans and deposits for 16 consecutive quarters. </p>

      <p align='justify'>These results reflect how well our model can perform, even in an uncertain environment.
         Coming out of a tough fourth quarter of 2019 for the markets and our industry, we entered 2020 amid widespread predictions of a global recession and potential turn of the business cycle.
         Against that backdrop, when the pandemic brought the global economy to its knees, everyone wrote us off, but we survived and thrived - as we said it would - from working collaboratively and creatively with clients to manage and grow their businesses in an increasingly complex environment characterized by trade routes shifting and supply chains realigning.
         We again proved the value of our global client network and unmatched ability to connect people and businesses to opportunities worldwide.</p>

      <p align='justify'>Our Institutional Clients Group turned in a strong performance, driving balanced 4% underlying revenue growth across our franchise that serves corporate (including 90% of Fortune 500 firms), investor and government clients and ultra high net worth households and individuals.
        4 International Financing Review summed up the source of our market and wallet share gains when it named YUGOS 2019 Bank of the Year: "YUGOS is a truly global - and unique - corporate bank that joins the developed and developing world." 
        Those connections run deep between our banking teams and clients and across products, sectors, markets and regions.</p>  

      <p align='justify'>Our industry-leading Treasury and Trade Solutions (TTS) business is the backbone of our institutional franchise because our global client network makes YUGOS the first call to manage cash, process payments and create solutions to supply chain challenges and provides opportunities for clients in multiple markets and currencies.
         TTS has established itself within our firm and industry as a pioneer in steering the shift from analog to digital platforms and processes. </p>  
      
      <p align='justify'>Our Markets and Securities Services business continues its strong performance having topped the Greenwich Associates' Global Fixed Income Dealer rankings for the fourth consecutive year.
         Securities Services grew deposit balances versus the prior year through new mandates and organic growth from existing clients. 
        YUGOS Private Bank retained the award for the Best Global Private Bank from the Financial Times for the second year, finishing 2019 with year-over-year growth across product areas.</p>  
      
      <p align='justify'>In August last year, at the Economic Forum - an organization of CEOs of major global companies, of which I am a member - announced a revised statement of purpose and principles for responsible firms, I found the lively debate that ensued healthy and reassuring, but for us at YUGOS, the approach is not new.
         The statement said simply that companies should take the interests of all stakeholders - including shareholders, colleagues, clients, suppliers and communities - into account when making decisions.
         With every action we take, we strive to demonstrate the value we contribute and the values we uphold. Ultimately, taking stakeholder interests into account benefits our shareholders.
         We have been on this journey for some time and welcome the actions companies are taking to hold themselves accountable to higher environmental, social and governance standards.</p> 

      <p align='justify'>In the communities we serve, one particular infrastructure gap is increasingly pressing: affordable housing.
         As urban populations soar and cities become less affordable for many, what has long been an acute shortage is becoming a crisis.
         In 2019, YUGOS ranked first among U.S. financiers of affordable housing for the 10th year in a row. We financed over USD 7 billion worth of affordable rental housing projects.
         In partnership with developers, nonprofits and governments, our YUGOS Community Capital team has helped to create or preserve nearly 500,000 affordable units over the past decade.</p>    

      <p align='justify'>The year concluded with us in a strong competitive position in terms of capital, liquidity, technology and, importantly, talent.
         Changes to my management team include a new President of YUGOS and CEO of Global Consumer Banking, Janardhan Fraser; a new CEO of our Institutional Clients Group, Prakash Kumar; a new CFO, Marquis Masson; and new heads of our three regions.
         Our Mission and Value Proposition describe not just what we do but how and why we do it. As we prepare for the future, I have never felt better about our financial strength and competitive position, as measured by all the relevant metrics: revenues, returns, income and capital.
         I have also never felt better or more confident about who we were, are, and propose to stand for.
         While there is no shortage of challenges ahead, I am confident in our ability to continue to rise and meet them.</p>    

<p>Sincerely,</p>
<p>James Mahalanobis,</p>
<p>Chairman (YUGOS).</p> </Tab>

<Tab label="MARKETS">
  <br></br>

  <Alert variant="success">
  <Alert.Heading></Alert.Heading>
  <p  align='justify'><b>With global uncertainty staring the world in the face, rising Covid-19 cases and weak macro data took a toll on investor sentiment.
   While sharp selling in rate sensitive stocks, bank, realty and auto stock, also dampened market sentiments, there is a strong opportunity to pick up value stocks and hold for the long term.
   Where for a short-term investor, bank CDs and Treasury securities are a good bet; fixed or indexed annuities or even indexed universal life insurance products can provide better returns than Treasury bonds over a longer time period.
   Increase in the vaccination drives also are expected to spark a positive revival of stock prices.</b></p>
</Alert>

<Alert variant="info">
  <Alert.Heading></Alert.Heading>
  <p  align='justify'><b>With the world's unbanked population estimated at 1.7 billion, Inclusive Finance is the need of the hour.
   We need to provide more than 3.3 million entrepreneurs, 3 million of them women, with access to capital and financial services across 54 countries.
   The “YUGOS Me” Transfer account has shown signs of promise attracting over 1.5 million customers, 80% formerly unbanked within 10 months of launch in 5 cities.
   The YUGOS Foundation's Pathways to Progress program, which started in 2014 in 10 countries has helped prepare more than 100,000 youth for the jobs of today through paid internships, mentorship, workforce training and leadership development.</b></p>
</Alert>

<Alert variant="warning">
  <Alert.Heading></Alert.Heading>
  <p  align='justify'><b>Increasing human isolation and self-sufficiency coupled with a comfort with technology show immense promise for Artificial intelligence, Machine learning and Blockchain applications show immense promise to help automate cash management, foreign exchange and fraud protection.
   There is an increased drive to reduce real estate office spends and consolidate data centre operations to allow for stronger implementation of security features as well as reduce operating expenses.</b></p>
</Alert>

</Tab>

<Tab label="ORGANIZATION"><div id="container">
        {/* <h2>Highcharts</h2> */}
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          ref="chartComponent1"
        />
      </div></Tab>

<Tab label="BALANCE SHEET">
      <table class="table table-bordered">
        <th>Fiscal year</th>
        <th>2014</th>
        <th>2013</th>
        <th>2012</th>
        <tbody>
          <tr><td>Cash</td>
          <td>6.03M</td>
          <td>1.75M</td>
          <td>4.43M</td></tr>
          <tr><td>Marketable Securities</td>
          <td>-</td>
          <td>-</td>
          <td>-</td></tr>
          <tr><td>Receivables</td>
          <td>23.77M</td>
          <td>4.35M</td>
          <td>1.3M</td></tr>
          <tr><td>Inventory</td>
          <td>13.67M</td>
          <td>5.42M</td>
          <td>2.57M</td></tr>
          <tr><td>Raw Materials</td>
          <td>3.89M</td>
          <td>4.45M</td>
          <td>1.55M</td></tr>
          <tr><td>Work In Progress</td>
          <td>-</td>
          <td>-</td>
          <td>-</td></tr>
          <tr><td>Finished Goods</td>
          <td>11.44M</td>
          <td>2.22M</td>
          <td>2.69M</td></tr>
          <tr><td>Notes Receivable</td>
          <td>-</td>
          <td>-</td>
          <td>-</td></tr>
          <tr><td>Other Current Assets</td>
          <td>3.15M</td>
          <td>0.74M</td>
          <td>0.39M</td></tr>
          <tr><td>Total Current Assets</td>
          <td>46.64M</td>
          <td>12.27M</td>
          <td>8.7M</td></tr>
          <tr><td>Property Plant and Equipment</td>
          <td>2.24M</td>
          <td>1.3M</td>
          <td>0.7M</td></tr>
          <tr><td>Accumulated Depreciation</td>
          <td>1.03M</td>
          <td>0.55M</td>
          <td>0.38M</td></tr>
          <tr><td>Net Property Plant and Equipment</td>
          <td>1.21M</td>
          <td>0.75M</td>
          <td>0.31M</td></tr>
          <tr><td>Investment and Advances</td>
          <td>-</td>
          <td>-</td>
          <td>-</td></tr>
          <tr><td>Other Non-Current Assets</td>
          <td>-</td>
          <td>-</td>
          <td>-</td></tr>
          <tr><td>Deferred Charges</td>
          <td>-</td>
          <td>-</td>
          <td>-</td></tr>
        </tbody>
      </table>
      </Tab>

<Tab label="BUSINESS CHALLENGES">
  <br></br>
<Card>
  <Card.Header><h6><b>Competitive Landscape</b></h6></Card.Header>
  <Card.Body>
    {/* <Card.Title>Special title treatment</Card.Title> */}
    <Card.Text>
    <p align='justify' >Many national banks are starting their equities arm. Names include BIS who has been one of YUGOS’s biggest client plans to start their own captive units for trading. Smaller banks are merging to take on the bigger players. In addition, many foreign multinational banks are eyeing the growing market with greed.</p>
    </Card.Text>
  </Card.Body>
</Card> 

  <br></br>

  <Card>
  <Card.Header><h6><b>Cost Pressures</b></h6></Card.Header>
  <Card.Body>
    {/* <Card.Title>Special title treatment</Card.Title> */}
    <Card.Text>
    <p  align='justify'>Non-traditional options like tech driven trading platforms have driven down commissions significantly. Earlier where the bank would earn 5% + commission, the number has shrunk to 0.5% - commission.</p>
    </Card.Text>
  </Card.Body>
</Card> 

<br></br>

  <Card>
  <Card.Header><h6><b>Technology</b></h6></Card.Header>
  <Card.Body>
    {/* <Card.Title>Special title treatment</Card.Title> */}
    <Card.Text>
    <p align='justify'>AI, ML and Blockchain have taken predictive analytics to the next level in terms of speed and accuracy. Traditional data gathering techniques have become outdated in the face of latest data analytic models.</p>
    </Card.Text>
  </Card.Body>
</Card> 

<br></br>

  <Card>
  <Card.Header><h6><b>Infrastructure</b></h6></Card.Header>
  <Card.Body>
    {/* <Card.Title>Special title treatment</Card.Title> */}
    <Card.Text>
    <p align='justify'>The sudden pandemic has resulted in a huge hit on the real estate space. Fearful employees refuse to come to office and large chunks of office space lie unutilized. Now employees are demanding that the company pay for their home network as well.</p>
    </Card.Text>
  </Card.Body>
</Card> 

<br></br>

  <Card>
  <Card.Header><h6><b>Quality and Energy Efficiency </b></h6></Card.Header>
  <Card.Body>
    {/* <Card.Title>Special title treatment</Card.Title> */}
    <Card.Text>
    <p  align='justify'>Mistakes are no longer cool. With trade volumes multiplying by at least X10 in the last 5 years, the upside as well as the cost of downside have become immense. Also increasing speed of transactions has left very little margin for error.</p>
    </Card.Text>
  </Card.Body>
</Card> 


</Tab>

<Tab label="CORE STRATEGIES">
  <br></br>

<Card>
  <Card.Header><h6><b>Improve growth and profitability</b></h6></Card.Header>
  <Card.Body>
    {/* <Card.Title>Special title treatment</Card.Title> */}
    <Card.Text>
    <p align='justify'>YUGOS is seeking significant top-line growth and needs to capitalize on key market opportunities and leverage resources wisely to maximize profit for the short and longer-term.</p>
    </Card.Text>
  </Card.Body>
</Card> 

<br></br>

 <Card>
  <Card.Header><h6><b>Focus on quality, Cost and Delivery</b></h6></Card.Header>
  <Card.Body>
    {/* <Card.Title>Special title treatment</Card.Title> */}
    <Card.Text>
    <p align='justify'>Enhance the quality, cost and delivery and thus enhance the employer brand, profits and the market penetration.</p>
    </Card.Text>
  </Card.Body>
</Card> 

<br></br>

<Card>
  <Card.Header><h6><b>Expand market presence</b></h6></Card.Header>
  <Card.Body>
    {/* <Card.Title>Special title treatment</Card.Title> */}
    <Card.Text>
    <p align='justify'>Tap into Inclusive Banking segment. YUGOS needs to accelerate expansion into the tier 2 and tier 3 cities across India. In the future, as much as 40% of revenue is expected to come from these cities.</p>
    </Card.Text>
  </Card.Body>
</Card> 

<br></br>

<Card>
  <Card.Header><h6><b>Enhance the value proposition</b></h6></Card.Header>
  <Card.Body>
    {/* <Card.Title>Special title treatment</Card.Title> */}
    <Card.Text>
    <p align='justify'>There is much room to sell value to customers through integrated offerings, add-on service contracts, technology integration and consultation. Creativity and collaboration across YUGOS regions is needed to support this strategy.</p>
    </Card.Text>
  </Card.Body>
</Card> 

<br></br>

<Card>
  <Card.Header><h6><b>Recruit and retain top talent</b></h6></Card.Header>
  <Card.Body>
    {/* <Card.Title>Special title treatment</Card.Title> */}
    <Card.Text>
    <p align='justify'>To execute strategies, YUGOS needs to recruit and retain the best talent available. YUGOS will aggressively develop and coach people, promote high-potential employees rapidly, and hire externally when it makes sense.</p>
    </Card.Text>
  </Card.Body>
</Card> 

  </Tab>
    
<Tab label="NEWS FEED">

<Accordion>
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="0">
      <h6><b>Bank of Baroda answers customers’ queries related to Vijaya Bank and Dena Bank integration (Dec 23rd 2020)</b></h6>
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="0">
      <Card.Body><p>Bank of Baroda (BoB) has completed the integration of 3,898 branches of Vijaya Bank and Dena Bank. With effect from April 1, 2019, Vijaya Bank and Dena Bank were merged with Bank of Baroda. Across India, customers will now have access to a total of 8,248 domestic branches and 10,318 ATMs, BoB said.</p>
<p>Bank of Baroda, on its official website, has answered some of customers' queries regarding the amalgamation. Here are some of the queries:</p>
<p>Will the account number change?</p>
<p>Customers’ account number will change on data Migration of customer's branch. Suitable communication regarding change in account number will be done before customer's branch migration. Customers can also get the information from the bank's Contact Centre. On registered mobile number with the bank, customers shall also receive a SMS.</p>
<p>Will Customer Identification Number (CIF) change?</p>
<p>The Customer Identification number will change. All customers’ accounts will be linked to the allotted customer number.</p>
<p>Will customers’ branches change?</p>
<p>Customers’ branches will become Bank of Baroda branches. Codes of all branches will change. In certain instances, there may be changes in branch name and address. On the amalgamation tab of the bank's website, the required information is also available.</p>
<p>Will IFSC and MICR code change?</p>
<p>Customer's IFSC and MICR code will change. Until intimated by the bank, customers can use the old IFSC and MICR codes.</p>
<p>Can we use existing debit cards?</p>
<p>With the current PIN, Vijaya Bank and Dena Bank customers can use their current debit cards till expiry. The account-holder will be prompted for change of PIN on first usage of the card at a BoB ATM.</p>
<p>What about digital facilities?</p>
<p>All internet banking facilities to be available on Bank of Baroda's website. All account-holders will need to download and install Bank of Baroda's app called M-Connect Plus to avail mobile banking services.</p>
<p>MONEYCONTROL NEWS</p></Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="1">
      <h6><b>Top Foreign Banks in India 2021 (January 9, 2021)</b></h6>
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="1">
      <Card.Body><p>It is now a well-established fact that India is one of the fastest-growing economies of the world.
   Hence, it didn’t come as a surprise when India received the highest Foreign Direct Investment (FDI) till date, from April to September 2020, amounting to a whopping $500.12 billion.
   As one might expect, this boom in the economy has led to the unparalleled growth of existing as well as new financial institutions in the country.
   As a matter of fact, as many as 46 foreign banks are operational in India in 2021.</p>
<p>The country enjoys two-fold benefits owing to the presence of these foreign entities; the first being boost in international transactions, and the second being increased employment opportunities for qualified nationals.
   For what they’re worth, the foreign banks in India have also helped India establish a firmer international presence while bringing in ease of business on a global scale for the domestic enterprises.</p>   
<p>List of the top foreign banks in India & their Headquarters in 2021</p>
<p>1. <b>Citi Bank</b> – Citibank, formerly known as City Bank of New York, is a multinational bank with its roots in New York (USA) from as early as 1812.
   Citibank India was established back in the year 1902, with the objective of offering various banking, investment, and advisory services, complete with risk management solutions and transaction facilities.
   Serving over 25 Lakh customers across the nation, the bank carries out its operations through as many as 43 branches and 750 ATMs. With Mr. Ashu Khullar as its CEO, the bank now employs over 7,500 people in India.</p>
<p>Product	Interest Rates:</p> 
<p>Citibank Personal Loan	10.05%</p> 
<p>Citibank Home Loan	6.75%</p> 
<p>Citibank Business Loan	12%</p> 
<p>Citibank Loan Against Property	7.30%</p> 
<p>2. <b>HSBC India</b> – HSBC Bank India is a subsidiary of the HongKong and Shanghai Banking Corporation. The Indian subsidiary headquartered in Mumbai offers numerous services including but not limited to retail banking, personal banking, debit and Credit Cards, loans, as well as foreign exchange amongst others.
   With more than 24 branches across the nation, the bank is well-known for offering India its first ATM, back in the year 1987.</p> 
<p>Product	Interest Rates:</p> 
<p>HSBC Personal Loan	9.99%</p> 
<p>HSBC Home Loan	7.30%</p> 
<p>3. <b>Deutsche Bank</b> – As the name suggests, the Deutsche Bank has its roots in Frankfurt, Germany. 
  The Indian subsidiary of the bank was established in the year 1980 in the financial capital of the country – Mumbai! Today, the bank enjoys as many as 17 branches across the country with a respectable customer base of 5 Lakh.
  Offering all the elementary banking services, the bank employs as many as 11,000 people.</p> 
<p>Product	Interest Rates:</p> 
<p>Deutsche Bank Personal Loan	24.00%</p> 
<p>Deutsche Bank Home Loan	9.85%</p> 
<p>Deutsche Bank Business Loan	16.50%</p> 
<p>Deutsche Bank Loan Against Property	8.25%</p> 
<p>4. <b>Royal Bank of Scotland</b> (NatWest Markets PLC) – The Royal Bank of Scotland was established in India in the year 1921 with the aim of offering a wide array of banking services.
   The bank, fairing amongst the best foreign banks in India, currently operates in the country through its 10 branches, strategically located in the leading metropolitan cities and offers debt capital market services, financial advisory as well as foreign exchange services amongst many others.
   In a recent change, it was announced that the bank’s Indian subsidiary shall now be known as NatWest Markets PLC.</p> 
<p>5. <b>DBS Bank</b> – Essentially a subsidiary of the Development Bank of Singapore, DBS was established with the precise objective of encouraging financial interaction between India and Singapore.
   Founded in the year 1994, the bank’s Indian headquarters are located in Mumbai.
   With as many as 12 branches in India, DBS Bank offers personal and Business Banking Services, complete with Wealth Management.</p> 
<p>Product Interest Rates</p> 
<p>DBS Bank Personal Loan		10.99%</p> 
<p>DBS Bank Home Loan		8.75%</p> 
<p>6. <b>Barclays Bank</b> – Enjoying a strong presence in India since the year 1990, the UK based Barclays PLC employs over 23,000 people in its banking, technology, and shared services verticals.
   Headquartered in Mumbai, the bank has as many as 7 branches across the nation.
   Some of the leading services offered by the bank include- Commercial Banking, Loans, Credit Cards, and Treasury Solutions amongst others.</p> 
<p>7. <b>Bank of America</b> - Yet another foreign bank that enjoys its headquarters in the city of Mumbai, the Bank of America, as the name suggests, finds its origins in the USA.
   With a total of 5 branches in India, the bank does an excellent job of supporting the international transactions between India and America.
   Established back in the year 1964, the bank has stood the test of time with over 5 decades of offering numerous services to its patrons.</p>   
<p>8. <b>Bank of Bahrain and Kuwait</b> – With its roots in the Middle East, the Bank of Bahrain and Kuwait was founded in the year 1971, in Bahrain.
   It was in the year 1986 that the bank was brought to India, in a bid to serve both –Residents as well as Non-Resident Indians.
   Today, the bank boasts of 4 branches in the country, located in Mumbai, Hyderabad, Aluva (Kerala) and New Delhi. </p> 
<p>9. <b>Doha Bank</b> – Established quite recently, in the year 2014, Doha Bank has shown incredible promise.
   Originally headquartered in Qatar, the bank enjoys a strong presence in India through its 3 branches in the cities of Mumbai, Chennai, and Kochi.
   The bank is known for offering a wide array of services including Corporate Banking, NRI Banking, Personal Banking, Loans, Trade Services as well as Foreign Exchange amongst others.</p> </Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="2">
      <h6><b>15 foreign banks keen to open branch in India (Nov 2019)</b></h6>
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="2">
      <Card.Body><p>Private sector banks are better capitalized, have better asset quality and have also reported profits in the last fiscal.
   Private sector banks are better capitalized, have better asset quality and have also reported profits in the last fiscal. </p> 
<p>As per RBI Data, 46 foreign banks are already operating in India including 2 banks operating in wholly-owned subsidiary (WOS) mode.</p> 
<p>Foreign banks, except those operating in WOS mode, require prior approval of the RBI for opening branches in India.</p> 
<p>India could have more foreign banks soon as in the case of other Asian countries like China, Singapore and Hong Kong with 15 overseas banks have been in touch with the Central Bank, to open branches in the country.</p> 
<p>As per RBI Data, 46 foreign banks are already operating in India including two banks operating in wholly-owned subsidiary (WOS) mode. RBI has informed the finance ministry that 15 banks have shown interest in opening their branches in India, said official sources.</p> 
<p>Foreign banks, except those operating in WOS mode, require prior approval of the RBI for opening branches in India. RBI has further apprised that the foreign banks open their branches in India based on their commercial judgement.</p> 
<p>With regard to banks operating in WOS mode, as per RBI norms, at least 25 percent of the total number of branches opened during a financial year, must be opened in unbanked rural (Tier 5 and Tier 6) centres that do not have a brick and mortar structure of any scheduled commercial bank for customer-based banking transactions.</p> 
<p>It is also mandatory for foreign banks to provide loans to farmers and scheduled castes/scheduled tribes for employment on the lines of Indian Banks.
   RBI has rules that foreign banks have to comply with RBI's extant priority sector guidelines.
   These guidelines provide for foreign banks with 20 branches and above to lend 40 percent of Adjusted Net Bank Credit (ANBC) or Credit Equivalent Amount of Off-Balance Sheet Exposure, whichever is higher to priority sector including lending to Weaker Sections including, inter-alia, scheduled castes/scheduled tribes.</p> 
<p>SBM Bank (India) Limited (Subsidiary of SBM Group, Mauritius) and DBS Bank India Limited (Subsidiary of DBS Bank Ltd) were issued licence on December 6, 2017 and October 4, 2018 respectively for carrying on banking business in India through Wholly Owned Subsidiary (WOS) mode.
   They have commenced operations from 01 December 2018 and 01 March 2019 respectively.</p> 
<p>The highest branches belong to Standard Chartered of 100 and CitiBank has 35 branches.
   HSBC has 26 branches.
   Deutsche Bank has 17. UK's Barclays Bank Plc has 6 branches.</p> 
<p>Mint.</p> 
</Card.Body>
    </Accordion.Collapse>
  </Card>
</Accordion>
</Tab>
</Tabs>

    </div>

</div>
            </React.Fragment>
        );
    }
}

export default Details;
