import React, { Component } from "react";
import logo from "../zayogro.png";
import "../App.css";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import AuthService from "../components/details/AuthService";
import { RouteComponentProps, Redirect } from "react-router-dom";
import axios from "axios";

class Login extends Component<RouteComponentProps> {
  state = { email: "", password: "" };

  async handleFormSubmit(event) {
    event.preventDefault();
    const postData = {
      username: this.state.email,
      password: this.state.password,
    };
    const response = await AuthService.doUserLogin(postData);
    console.log(response);
    //alert(response);
    if (response) {
      AuthService.handleLoginSuccess(response, this.state.isChecked);
      // CookieService.set("userid",response.data,options);

      // localStorage.setItem('userid', response);
      if(localStorage.getItem('company')==2)
      {
        this.props.history.push("/Introduction1");
      }
      else{
        this.props.history.push("/Introduction");
      }
      
      // history.push("/Introduction");
      // localStorage.setItem('mydata',)
      // return <Redirect to="/Introduction" />;
    } else {
      alert("Please check your credentials and try again");
    }
  }

  render() {
    const { email, password } = this.state;
    return (
      <React.Fragment>
        <Form
          className="login-app" style={{ backgroundColor: "#FFFFFF" }}
          onSubmit={(event) => this.handleFormSubmit(event)}
        >
          <img src={logo} className="login-logo mt-5 ml-2 mx-auto" />
          {/* <h1><span className="font-weight-bold">ZayoGro</span></h1> */}
          <h2 className="text-center font-weight-bold mt-3 mb-3 ">E-Volve</h2>
          <FormGroup>
            <Label>Email</Label>
            <Input
              type="email"
              name="email"
              placeholder="email"
              value={email}
              onChange={(event) => this.setState({ email: event.target.value })}
              style={{ borderRadius: "15px" }}
            />
          </FormGroup>
          <FormGroup>
            <Label>Password</Label>
            <Input
              type="password"
              name="password"
              placeholder="password"
              value={password}
              onChange={(event) =>
                this.setState({ password: event.target.value })
              }
              style={{ borderRadius: "15px" }}
            />
          </FormGroup>
          <Button type="submit" className="btn-lg btn-info btn-block rounded-pill">
            Login  
          </Button>
        </Form>

        {/* <Button className="btn-lg btn-info btn-block">
        Login
      </Button> */}

        {/* <button type="submit" className="btn btn-info btn-block" >
      Sign In
    </button> */}
      </React.Fragment>
    );
  }
}

export default Login;
