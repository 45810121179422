let apiDomain = "https://evolveadmin.zayogro.com/";


class UrlService {
  static loginUrl() {
    return apiDomain + "api/logins";
  }
  static currentUserProfileUrl() {
    return apiDomain + "api/user";
  }
  static saveUserProfileUrl() {
    return apiDomain + "api/user";
  }
  static getCurrentUserAcitiviesUrl() {
    return apiDomain + "api/activities";
  }
  static getTodoUrl() {
    return apiDomain + "api/todos";
  }
  static markTodoCompleteUrl(id) {
    return apiDomain + "api/todo/complete/" + id;
  }
  static changeTodoOrderUrl() {
    return apiDomain + "api/todo/reorder";
  }
  static testAttemptUrl() {
    return apiDomain + "api/testattempt";
  }
  static depUrl() {
    return apiDomain + "api/dep";
  }
  static ConUrl() {
    return apiDomain + "api/get-country-list";
  }
  static StaUrl() {
    return apiDomain + "api/get-state-lists";
  }
  static citUrl() {
    return apiDomain + "api/get-city-lists";
  }
  static desgUrl() {
    return apiDomain + "api/get-desig-list";
  }
  static depfunUrl() {
    return apiDomain + "api/get-depfun-list";
  }
  static CanUrl(id) {
    return apiDomain + "api/can/"+id;
  }
   static TestUrl(test) {
    return apiDomain + "api/test/"+test;
  }
  static PdfUrl(id) {
    return apiDomain + "api/pdf/"+id;
  }
  static PreviewUpdateUrl()
  {
  return apiDomain + "api/previewupdate";
  }
  static AnswerUpdateUrl()
  {
  return apiDomain + "api/answerupdate";
  }
  static DraftUpdateUrl()
  {
  return apiDomain + "api/draftupdate";
  }
  static DiscardUpdateUrl()
  {
  return apiDomain + "api/discardupdate";
  }
  static TestLoggerUrl()
  {
  return apiDomain + "api/testlog";
  }
  static TestEndLoggerUrl()
  {
  return apiDomain + "api/testendlog";
  }
  static Set1Url()
  {
  return apiDomain + "api/set1";
  }

}

export default UrlService;
