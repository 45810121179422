import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import './index.css';
import App from './App';
//import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.css';


//render(<App/>,window.document.getElementById('app'))

ReactDOM.render(
  <BrowserRouter>
    <App />,
    </BrowserRouter>,
  document.getElementById('root')
);

// ReactDOM.render(
//   // <React.StrictMode>
//     <App />,
//   // </React.StrictMode>,
//   document.getElementById('App')
// );

// ReactDOM.render(<App />, document.getElementById('root'));

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
