import React, { Component } from "react";
import "./App.css";
import Login from "./components/login";
import Mail from "./components/email/email";
import Details from "./components/details/Details";
import Introduction from "./components/introduction/intro";
import Storyline from "./components/storyline/story";
import Thanks from "./components/thankyou/thanks";
import Website from"./components/website/home";

import Mail1 from "./components/email/email_copy";
import Details1 from "./components/details/Details_copy";
import Introduction1 from "./components/introduction/intro_copy";
import Storyline1 from "./components/storyline/story_copy";
import Thanks1 from "./components/thankyou/thanks_copy";
import Website1 from"./components/website/home_copy";

import { Route, Switch, Redirect } from "react-router-dom";
import "./components/email/email.css";

class App extends Component<props> {
  render() {
    return (
      //  <Router>
      //    <Route path='Login' component={Login}/>
      //  </Router>
      <Switch>
        {/* <Route path="/" component={Login} /> */}
        <Route exact path="/">
          <Redirect to="/Login" />{" "}
        </Route>
        <Route path="/Login" component={Login} />
        <Route path="/Introduction" component={Introduction} />
        <Route path="/Details" component={Details} />
        <Route path="/Storyline" component={Storyline} />
        <Route path="/Mail" component={Mail} />
        <Route path="/Thanks" component={Thanks} />
        <Route path="/welcome" component={Website} />

        <Route path="/Introduction1" component={Introduction1} />
        <Route path="/Details1" component={Details1} />
        <Route path="/Storyline1" component={Storyline1} />
        <Route path="/Mail1" component={Mail1} />
        <Route path="/Thanks1" component={Thanks1} />
        <Route path="/welcome1" component={Website1} />

        {/* <Route path="/Details" component={Details} />
                <Route exact path="/" component={App} /> */}

        {/*<Route path="/contact" component={Contact} />*/}
      </Switch>
    );
  }
}

// class App extends React.Component {
//   render(){
//   return (
//     // <Form className="login-app">
//     //   <img src={logo} className="login-logo mt-5" />
//     //   {/* <h1><span className="font-weight-bold">ZayoGro</span></h1> */}
//     //   <h2 className="text-center font-weight-bold mt-3 mb-3">E-volve</h2>
//     //   <FormGroup>
//     //     <Label>Email</Label>
//     //     <Input type="email" placeholder="email"/>
//     //   </FormGroup>
//     //   <FormGroup>
//     //     <Label>Password</Label>
//     //     <Input type="password" placeholder="password"/>
//     //   </FormGroup>
//     //   <Button className="btn-lg btn-info btn-block">
//     //     Login
//     //   </Button>
//     // </Form>
//     <login></login>
//   );
// }
// }

//render(<App/>,window.document.getElementById('App'))

export default App;
