import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
// import "./_style.scss";
import '../email/email.css';
import'../../App.css';
import logo from '../../zayogro.png';
// import AuthService from "../components/details/AuthService";
import axios from'axios';
// import CookieService from "./CookieService";
import { Col, Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';


class Details extends Component<RouteComponentProps> {
    state = {
        firstname: "",
        middlename: "",
        lastname: "",
        company: "",
        gender: "",
        department: "",
        age: "",
        totalexp: "",
        currentexp: "",
        testpur: "",
        isChecked: false,
    };
   

    async handleFormSubmit(event) {

        event.preventDefault();

        const postData = {
            // firstname: this.state.firstname,
            // middlename: this.state.middlename,
            // lastname: this.state.lastname,
            company: this.state.company,
            gender: this.state.gender,
            department: this.state.department,
            age: this.state.age,
            totalexp: this.state.totalexp,
            currentexp: this.state.currentexp,
            testpur: this.state.testpur,
        };

        const usid = localStorage.getItem('userid');
        this.props.history.push("/Details1");
        
    }

    handleChecked() {
        this.setState({ isChecked: !this.state.isChecked });
    }

    render() {
        const { firstname, middlename, lastname, company,gender,department,age,totalexp,currentexp,testpur} = this.state;
        const usid = localStorage.getItem('userid');
        return (
            <React.Fragment>
<div className="container-fluid" style={{ backgroundColor: "#FFFFFF" }}>
{/* <nav className="navbar navbar-light bg-primary"><img src={logo} className="login-logo-small mt-1 h-vmin"/><p style={{fontWeight:"700",fontSize:"1.5rem"}}>Evolve</p></nav> */}
<nav className="navbar navbar-light  mt-0 "  >
    <img src={logo} className="login-logo-small mt-1 h-5vmin" alt=""></img>
    <p style={{fontWeight:"700",fontSize:"1.7rem",color:"white",textShadow: "4px 4px 8px #000000"}}>E-Volve</p>
    </nav>
    <div className="content-wrapper">
        <div className="email-wrapper wrapper">
            <div className="row align-items-stretch">
              <div className="mail-sidebar d-none d-lg-block col-md-2 pt-3 bg-white">
                    <div className="menu-bar">
                            <ul className="menu-items">
                            {/* <img src={logo} className="login-logo-small mt-3"/> */}
                                <li><i className="mdi mdi-email-outline"></i> Introduction</li>
                                <li><i className="mdi mdi-share"></i> Personal Details</li>
                                <li><i className="mdi mdi-upload"></i> Storyline</li>
                                <li><i className="mdi mdi-file-outline"></i> Mailbox</li>
                                <li><i className="mdi mdi-star-outline"></i> Thank You</li>
                                {/* <li><a href="#"><i class="mdi mdi-delete"></i> Trash</a></li> */}
                            </ul>                            
                    </div>
              </div>

                    <div className="mail-view d-none d-md-block col-md-9  bg-white mt-5 ">

        <p>Hello {localStorage.getItem('uname1')}</p>
        <p>Welcome to the E-Volve Experience.</p>
        <p>Congratulations on being selected for this program. You are here because you have proved yourself and are among the best in your field of endeavour.</p>
        <p>The Purpose of this process is to gain a deeper understanding into your knowledge and approach in various situations that you may have / will be facing as a part of your day-day responsibilities through a simulation based scenario.</p>
        <p>We commend you for having made it this far and would like to take you through the coming steps:</p>
        <p><b>Step 1</b>: Enter your details : Please submit your personal details including name, experience etc. before beginning the simulation.</p>
        <p><b>Step 2</b>: Read the case: You will be provided with a case storyline involving your character for the remainder of the exercise. The storyline will reveal the background, your role and expected involvement in the exercise. You will be given 5 minutes </p>
        <p><b>Step 3</b>: Load the simulation: Once you have read through the case, please load the program to begin.</p>
        <p><b>Step 4</b>: Load “Company” details: On the top left-hand side of the system, you will find a button marked “Company”. Clicking on this will open up a company website which will give the background to the exercise and different information like Company History, President’s Message, Org Strategy, Org Chart, Industry News. You can refer to this while answering different mails. This will remain open for you in separate tab to refer to during any part of the exercise.</p>
        <p><b>Step 5</b>: Load “My Mail”: Click on the “My Mail” button right next to the “Org Details” to go back to Mail box. Start looking at different mails and answering them as per your convenience. Clicking on each mail shows you a preview of the mail with 2 buttons Reply and Reply All at top. You can respond to each mail by clicking on the buttons as you like.</p>
        <p>•	Reply Button: allows you to respond to the sender alone.</p>
        <p>•	Reply All Button:: allows you to respond to all parties in the original mail including those in CC as well</p>
        <p><b>Step 6</b>: Once you are finished with your mails, please submit and exit.</p>
        <p>You have 15 mins to complete the process.</p>
        <p>Wish you all the very best !!!</p>
        <p>ZayoGro</p>

                        
        <div className="row">
                            {/* <div className="col-8">

                            </div> */}
                            <div className="col-2">
                                <button
                                    type="button"
                                    className="btn btn-primary btn-block"
                                        onClick={()=>{this.props.history.push("/details1")}}
                                >
                                    Next
                                </button>
                            </div>
                        </div>   
       
                    </div> 
              
            </div>
        </div>
    </div>
</div>
            </React.Fragment>
        );
    }
}

export default Details;
