import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
// import "./_style.scss";
import '../email/email.css';
import'../../App.css';
import logo from '../../zayogro.png';
// import AuthService from "../components/details/AuthService";
import axios from'axios';
// import CookieService from "./CookieService";
import { Col, Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { Container, Row, Nav, NavItem, Navbar,Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,ModalTitle } from "react-bootstrap";
import {Tab,Tabs,T} from 'react-bootstrap-tabs'; 
import Highcharts from 'highcharts';
import sankey from "highcharts/modules/sankey.js";
import organization from "highcharts/modules/organization.js";
import HighchartsReact from 'highcharts-react-official';  

sankey(Highcharts);
organization(Highcharts);

const options = {
  chart: {
    height: 600,
    inverted: true
  },
  title: {
    text: 'Organazation chart'
  },
  accessibility: {
    point: {
      descriptionFormatter: function(point) {
        var nodeName = point.toNode.name,
          nodeId = point.toNode.id,
          nodeDesc = nodeName === nodeId ? nodeName : nodeName + ', ' + nodeId,
          parentDesc = point.fromNode.id;
        return point.index + '. ' + nodeDesc + ', reports to ' + parentDesc + '.';
      }
    }
  },
  series: [{
    type: 'organization',
    name: 'Highsoft',
    keys: ['from', 'to'],
    data: [
      ['Shareholders', 'CEO'],
      ['Shareholders', 'CEO1'],
      ['Shareholders', 'CEO2'],
      ['Shareholders', 'CEO3'],
      ['Shareholders', 'CEO4']
      ['CEO', 'CEO'],
      ['CEO', 'CTO'],
      ['CEO', 'CPO'],
      ['CEO', 'CSO'],
      ['CEO', 'HR'],
      ['CTO', 'Product'],
      ['CTO', 'Web'],
      ['CSO', 'Sales'],
      ['HR', 'Market'],
      ['CSO', 'Market'],
      ['HR', 'Market'],
      ['CTO', 'Market']
    ],
    levels: [{
      level: 0,
      color: 'silver',
      dataLabels: {
        color: 'black'
      },
      height: 25
    }, {
      level: 1,
      color: 'silver',
      dataLabels: {
        color: 'black'
      },
      height: 25
    }, {
      level: 2,
      color: '#980104'
    }, {
      level: 4,
      color: '#359154'
    }],
    nodes: [{
      id: 'Shareholders',
      title:'Regional Head of North',
      name:'Sumit Srivastava'
    }, {
      id: 'CEO',
      title:'Regional HR',
      name:'Alok Sharma'
    }, {
      id: 'CEO1',
      title: 'Trade Activation Manager North',
      name: 'Gourav Goyal'
     
    }, {
      id: 'HR',
      title: 'HR/CFO',
      name: 'Anne Jorunn Fjærestad',
      color: '#007ad0',
     
    }, {
      id: 'CTO',
      title: 'CTO',
      name: 'Christer Vasseng',
      
    }, {
      id: 'CPO',
      title: 'CPO',
      name: 'Torstein Hønsi',
      
    }, {
      id: 'CSO',
      title: 'CSO',
      name: 'Anita Nesse',
      
    }, {
      id: 'Product',
      name: 'Product developers'
    }, {
      id: 'Web',
      name: 'Web devs, sys admin'
    }, {
      id: 'Sales',
      name: 'Sales team'
    }, {
      id: 'Market',
      name: 'Marketing team',
      column: 5
    }],
    colorByPoint: false,
    color: '#007ad0',
    dataLabels: {
      color: 'white'
    },
    borderColor: 'white',
    nodeWidth: 65
  }],
  tooltip: {
    outside: true
  },
  exporting: {
    allowHTML: true,
    sourceWidth: 800,
    sourceHeight: 600
  }

};



class Details extends Component<RouteComponentProps> {
    state = {
        firstname: "",
        middlename: "",
        lastname: "",
        company: "",
        gender: "",
        department: "",
        age: "",
        totalexp: "",
        currentexp: "",
        testpur: "",
        isChecked: false,  
    };
   

    async handleFormSubmit(event) {

        event.preventDefault();

        const postData = {
            // firstname: this.state.firstname,
            // middlename: this.state.middlename,
            // lastname: this.state.lastname,
            company: this.state.company,
            gender: this.state.gender,
            department: this.state.department,
            age: this.state.age,
            totalexp: this.state.totalexp,
            currentexp: this.state.currentexp,
            testpur: this.state.testpur,
        };

        const usid = localStorage.getItem('userid');
        this.props.history.push("/Details");
        // axios.put('http://localhost:8000/api/formSubmit/'+usid, postData)
        // .then(function (response) {

        //     console.log(response.data);

        // })

        // .catch(function (error) {

        //     console.log(error);

        // });
        
        

        
        // const response = await AuthService.doUserLogin(postData);
        // if (response) {
        //     AuthService.handleLoginSuccess(response, this.state.isChecked);
        //     this.props.history.push("/dashboard");
        // } else {
        //     alert("Please check your credentials and try again");
        // }
    }

    handleChecked() {
        this.setState({ isChecked: !this.state.isChecked });
    }

    render() {
        const { firstname, middlename, lastname, company,gender,department,age,totalexp,currentexp,testpur} = this.state;
        const usid = localStorage.getItem('userid');
        return (
            <React.Fragment>
<div className="container-fluid" style={{ backgroundColor: "#FFFFFF" }}>
<Navbar
          className=" navbar-light mt-0"
          // style={{ backgroundColor: "#C0C0C0" }} //blue=#0173c7 ash=#bfc9ca
          // style={{ backgroundColor: "#b8e2f2" }} 
        >
          {/* <img className="login-logo-small" src={logo} alt=""></img> */}

          <Nav>
            <NavItem>
              <p
                style={{
                  fontWeight: "700",
                  fontSize: "2rem",
                  color: "white",
                  textShadow: "4px 4px 8px #000000"
                }}
              >
                YUGOS
              </p>
            </NavItem>
          </Nav>

          <Nav className="mr-auto ml-auto">
            <NavItem>
              {/* <Button className="btn btn-primary btn-group btn-sm ml-3 rounded-pill " type="button"  >
                Home
              </Button> */}
            {/* </NavItem>
            <Nav.Link href="/">Home</Nav.Link>
            <NavItem> */}
              {/* <Button className="btn btn-primary btn-group btn-sm ml-3 rounded-pill " type="button" >
            MESSAGE OF CHAIRMAN
              </Button> */}
            {/* </NavItem>
            <NavItem>
              <Button className="btn btn-primary btn-group btn-sm ml-3 rounded-pill " type="button" >
            MARKETS
              </Button>
            </NavItem>
            <NavItem>
              <Button className="btn btn-primary btn-group btn-sm ml-3 rounded-pill " type="button" >
            ORGANIZATION
              </Button>
            </NavItem>
            <NavItem>
              <Button className="btn btn-primary btn-group btn-sm ml-3 rounded-pill " type="button" >
            BALANCE SHEET
              </Button>
            </NavItem>
            <NavItem>
              <Button className="btn btn-primary btn-group btn-sm ml-3 rounded-pill " type="button" >
            BUSINESS CHALLENGES
              </Button>
            </NavItem>
            <NavItem>
              <Button className="btn btn-primary btn-group btn-sm ml-3 rounded-pill " type="button" >
            BUSINESS CHALLENGES
              </Button>
            </NavItem>
            <NavItem>
              <Button className="btn btn-primary btn-group btn-sm ml-3 rounded-pill " type="button" >
            NEWS FEED
              </Button> */}
            </NavItem>
            
          </Nav>
          
          
          <Nav>
            <NavItem>
              <p
                style={{
                  fontWeight: "700",
                  fontSize: "1.7rem",
                  color: "white",
                  textShadow: "4px 4px 8px #000000"
                }}
              >
                E-Volve
              </p>
            </NavItem>
          </Nav>
        </Navbar>

        <div className="container-fluid">
        <Tabs onSelect={(index, label) => console.log(label + ' selected')}>
    <Tab label="HOME"><p>On May 8, 1976 Dr. Apurba Sarkar produced the syrup for Pocomo, a natural herbal preservative free pomegranate drink that could be stored for upto 6 months using a mix of natural and herbal ingredients that has been a closely guarded family secret since. He carried a jug of the new product down the street to market place where it was sampled, pronounced "excellent" and placed on sale for INR 5 a glass as a refreshing drink. During the first year, sales averaged a modest 10 drinks per day and continued to grow at a modest pace.</p>

<p>Dr. Apurba was always focused on the health aspects of the Pocomo and never realized the true potential of his creation. He gradually sold portions of his business to various partners and, just prior to his death in 1985, sold his remaining interest to his accountant Vibhu Mehta. A Gujarati with great business acumen and marketing flair, Mr. Mehta proceeded to buy out the additional rights and acquire complete control by 1988.</p> </Tab>
    <Tab label="MESSAGE OF CHAIRMAN"><p>Dear Shareholders It gives me great pleasure to inform you that Your Company performed well during 2015-16 with steady growth in its revenues and profitability.</p>

<p>The year was marked by a further slow-down in the FMCG industry with demand continuing to be sluggish and rural economy coming under stress due to a below normal monsoon and unseasonal rains.</p>

<p>Moving forward on its journey to make itself Future Ready, the Company rolled out a host of new products and campaigns aimed at the new generation. These include initiatives in baby care, hair and skin care, beverages and OTC health.</p>

<p>The Company uses various communication channels to reach out to its consumers. Our new brands and campaigns, targeting the youth, continued to rule the consumer mindspace and won a number of accolades during the year.</p>

    <p>YUGOS Nariyal Hair Oil was named the best hair oil at Femina Beauty Awards. YUGOS’s Hair Oil and Toothpaste brands were ranked in Top 100 Most Trusted Brands 2015 List by Brand Equity.
    Hajmola Yoodley was ranked among the Buzziest Brands of 2015 by Afaqs. Our campaigns bagged 8 awards at Goafest 2015, including the Grand Prix, in addition to winning the Blue Elephant at Kyoorius Awards 2015 in ‘Advertising for Good’ category.
    - At this point I would like to highlight a few important trends in the consumer sector which are likely to have a significant impact on consumer behaviour and consumption going forward. One of these is the emerging preference for Ayurvedic, herbal and natural products which is gaining momentum. Your Company has been the pioneer for Ayurveda based health and personal care products in India and is set to gain from this trend.</p>


<p>YUGOS is taking cognizance of these changes and crafting its future strategies in line with these developments.
The Company is also planning to enhance its range of healthcare products based on Ayurvedic, herbal solutions to address the emerging health care issues. Your company has a strong R&D infrastructure which has been carrying on research on Ayurveda and herbal products for a number of decades.
Overall your Company is positioned well to tap some of the emerging opportunities and is investing strongly in innovation, enhancing its consumer connect and optimizing its distribution networks to deliver superior growth across its markets and categories. Investments in manufacturing and supply chain are lined up to strengthen the competitive advantage. The Company is also investing in creating a strong IT infrastructure to leverage technology for enhancing efficiencies and productivity.
I would like to take this opportunity to thank our dedicated and motivated employees who are the greatest asset of the Company and who have with their passion, hard work and commitment taken it to this level. We look forward to continuing on the path of excellence and profitable growth into the coming years.</p>

<p>Sincerely yours,</p>
<p>Vibhu Mehta</p>
<p>Chairman.</p> </Tab>
    <Tab label="MARKETS"><p>The demand for Ayurvedic and natural products has been growing at a steady pace with more and more consumers embracing these products. This category has, of late, seen heightened activity with the entry of new players, both domestic and international. YUGOS’s core philosophy is rooted in Ayurveda having been associated with this traditional system of healthcare for more than a century.</p>

<p>YUGOS continued to invest behind its brands and rolled out a host of new products targeting the youth. The Company continued to introduce new products, invest in enhancing its distribution network and communicating effectively with its consumers to spread awareness about the high quality of its products as also the benefits that they offer.</p>

<p>YUGOS has highly differentiated brands in the market, and nearly all its products are based on natural and Ayurvedic ingredients. While a lot of companies today offer herbal or Ayurvedic products, YUGOS enjoys the consumer’s trust having the rich Ayurvedic heritage and consumers’ trust for over half a century. YUGOS has been ranked among the top Ayurveda brands in India by PAVA Brand Research Private Limited.</p> </Tab>
    <Tab label="ORGANIZATION"><div>
        <h2>Highcharts</h2>
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          ref="chartComponent1"
        />
      </div></Tab>
    <Tab label="BALANCE SHEET">
      <table class="table table-bordered">
        <th>Fiscal year</th>
        <th>2014</th>
        <th>2013</th>
        <th>2012</th>
        <tbody>
          <tr><td>Cash</td>
          <td>6.03M</td>
          <td>1.75M</td>
          <td>4.43M</td></tr>
          <tr><td>Marketable Securities</td>
          <td>-</td>
          <td>-</td>
          <td>-</td></tr>
          <tr><td>Receivables</td>
          <td>23.77M</td>
          <td>4.35M</td>
          <td>1.3M</td></tr>
          <tr><td>Inventory</td>
          <td>13.67M</td>
          <td>5.42M</td>
          <td>2.57M</td></tr>
          <tr><td>Raw Materials</td>
          <td>3.89M</td>
          <td>4.45M</td>
          <td>1.55M</td></tr>
          <tr><td>Work In Progress</td>
          <td>-</td>
          <td>-</td>
          <td>-</td></tr>
          <tr><td>Finished Goods</td>
          <td>11.44M</td>
          <td>2.22M</td>
          <td>2.69M</td></tr>
          <tr><td>Notes Receivable</td>
          <td>-</td>
          <td>-</td>
          <td>-</td></tr>
          <tr><td>Other Current Assets</td>
          <td>3.15M</td>
          <td>0.74M</td>
          <td>0.39M</td></tr>
          <tr><td>Total Current Assets</td>
          <td>46.64M</td>
          <td>12.27M</td>
          <td>8.7M</td></tr>
          <tr><td>Property Plant and Equipment</td>
          <td>2.24M</td>
          <td>1.3M</td>
          <td>0.7M</td></tr>
          <tr><td>Accumulated Depreciation</td>
          <td>1.03M</td>
          <td>0.55M</td>
          <td>0.38M</td></tr>
          <tr><td>Net Property Plant and Equipment</td>
          <td>1.21M</td>
          <td>0.75M</td>
          <td>0.31M</td></tr>
          <tr><td>Investment and Advances</td>
          <td>-</td>
          <td>-</td>
          <td>-</td></tr>
          <tr><td>Other Non-Current Assets</td>
          <td>-</td>
          <td>-</td>
          <td>-</td></tr>
          <tr><td>Deferred Charges</td>
          <td>-</td>
          <td>-</td>
          <td>-</td></tr>
        </tbody>
      </table>
      </Tab>
    <Tab label="BUSINESS CHALLENGES"><h6><b>HEALTH CONSCIOUSNESS DRIVES GROWTH IN HEALTH AND WELLNESS FOOD AND BEVERAGES</b></h6>
<p>Growing health consciousness over the review period continued to drive demand for natural food and beverages in India. The trend was also visible in the growing number of gym memberships and increased uptake of weight control diets. To tap into the trend, manufacturers continued to add new products to their portfolios, which transitioned from niche to larger market segments.</p>

<h6><b>CONSUMERS RECOGNIZE THE APPEAL OF NATURALLY HEALTHY PRODUCTS</b></h6>
<p>With increasing lifestyle-related diseases and health conditions, naturally healthy products remained appealing to consumers and thus witnessed the highest value growth in 2015. The rise in the number of citizens suffering from high cholesterol, heart-related diseases and non-communicable diseases resulted in consumers purchasing more healthy packaged food and beverages.</p>

<h6><b>MULTINATIONALS MAINTAIN THE LEAD IN HEALTH AND WELLNESS</b></h6>
<p>Multinationals, such as PepsiCo and Red Bull, continued to have a strong foothold in health and wellness beverages due to their long established presence in India. However, domestic companies, including YUGOS, Dabur and Pioma Industries, have begun to expand their HW portfolio and compete against multinationals over the review period.</p>

<h6><b>MODERN RETAIL DRIVES GROWTH OF HEALTH AND WELLNESS</b></h6>
<p>Although traditional grocery retailers remained the leading channel, the contribution of modern grocery retailers increased over the review period. Supermarkets and hypermarkets were popular channels for HW products as these outlets offer point-of-sale promotions and prominent shelf space and also cater to urban consumers, who are the largest purchasing segment for HW products. This also supported the development of new products in the HW market, such as organic RTD beverages by Sresta Natural Bio Products.</p>

<h6><b>HEALTH AND WELLNESS VALUE SALES LIKELY TO GROW FURTHER</b></h6>
<p>Health and wellness is set to continue to record strong value growth over the forecast period due to rising consumer health awareness and rising disposable incomes. These products will cater to health-conscious consumers, who are expected to opt for products which reduce cholesterol, help control or avoid diabetes and manage weight, as well as maintain good health.</p>

<h6><b>Competitive Landscape</b></h6>
<p>YUGOS has experienced increased competition from organizations which have brought in new and improved products in the markets. While new competitors are entering the market every year, there is also increasing consolidation in the industry through record numbers of mergers and acquisitions and other partnerships.</p>
<h6><b>Cost Pressures</b></h6>
<p>Over the last several years, customers have increased pressure on YUGOS to cut costs because of the widespread competition available in the market. Customers have publicly stated that they will require suppliers to reduce costs by as much as 5% over the next year. Thus, margins are being squeezed in an increasingly competitive industry during a very trying period for the world economy, and it will be more difficult to achieve double-digit profitability than in previous years. Without a clear cost reduction plan, questions are being raised as to the long-term prospects of YUGOS being a major point of concern.</p>
<h6><b>Technology</b></h6>
<p>Technological development in industry is occurring at a faster pace than ever before. YUGOS, as well as other companies in the industry, need to find ways to develop products more quickly, without sacrificing quality, to maintain or improve upon their competitive positions.</p>
<h6><b>Infrastructure</b></h6>
<p>From an internal perspective, it is difficult often to manage YUGOS’s internal relationships and business unit partnerships. Additionally, the competition for talent, especially at the leadership level, has limited YUGOS’s aspirations to plan for growth and innovation in the North region.</p>
<h6><b>Quality and Energy Efficiency</b></h6>
<p>In the FMCG industry quality and efficiency are the key. The need of the hour is to develop products which cater to both highest standards of quality and efficiency in delivering the “Scientific Ayurveda” promise to the consumer’s doorstep.</p></Tab>
    <Tab label="CORE STRATEGIES"><h6><b>Improve growth and profitability</b></h6>
    <p>YUGOS is seeking significant top-line growth and needs to capitalize on key market opportunities and leverage resources wisely to maximize profit for the short and longer-term.</p>
  <h6><b>Focus on quality, Cost and Delivery</b></h6>
	<p>Enhance the quality, cost and delivery and thus enhance the employer brand, profits and the market penetration.</p>
  <h6><b>Expand market presence</b></h6>
	<p>A key growth strategy will be to accelerate the expansion of YUGOS into the tier 2 and tier 3 cities of North India. In the future, as much as 40% of revenue is expected to come from these cities.</p>
  <h6><b>Enhance the value proposition</b></h6>
	<p>Sales forces are promoting YUGOS products, but there is still much room to sell value to customers through integrated offerings, add-on service contracts, and consultation. Creativity and collaboration across YUGOS regions is needed to support this strategy.</p>
  <h6><b>Recruit and retain top talent</b></h6>
	<p>To execute strategies, YUGOS needs to recruit and retain the best talent available. YUGOS will aggressively develop and coach people, promote high-potential employees rapidly, and hire externally when it makes sense.</p></Tab>
    <Tab label="NEWS FEED">Tab 2 content</Tab>
</Tabs>
    </div>

</div>
            </React.Fragment>
        );
    }
}

export default Details;
