import axios from "axios";
import UrlService from "./UrlService";
import CookieService from "./CookieService";

const expiresAt = 60 * 24;

interface Credentials {
  username: string;
  password: string;
}

class AuthService {
  async doUserLogin(credentials: Credentials) {
    try {
      const response = await axios.post(UrlService.loginUrl(), credentials);
      return response.data;
    } catch (error) {
      console.error("Error", error.response);
      return false;
    }
  }
  handleLoginSuccess(response: any, remember: boolean) {
    if (!remember) {
      const options = { path: "/" };
      // CookieService.set("access_token", response.access_token, options);
      CookieService.set("userid",response.uid,options);
      localStorage.setItem('userid', response.uid);
      localStorage.setItem('uname1', response.uname1);
      localStorage.setItem('uname2', response.uname2);
      localStorage.setItem('uname3', response.uname3);
      localStorage.setItem('test', response.test);
      localStorage.setItem('mail', response.mail);
      localStorage.setItem('company', response.company);
      localStorage.setItem('test_category', response.test_category);
      localStorage.setItem('reply_mail', response.reply_mail);
      localStorage.setItem('cc_mail', response.cc_mail);
      return true;
    }

    let date = new Date();
    date.setTime(date.getTime() + expiresAt * 60 * 1000);
    const options = { path: "/", expires: date };
    // CookieService.set("access_token", response.access_token, options);
    CookieService.set("userid",response.uid,options);
    localStorage.setItem('userid', response.uid);
    localStorage.setItem('uname1', response.uname1);
    localStorage.setItem('uname2', response.uname2);
    localStorage.setItem('uname3', response.uname3);
    localStorage.setItem('test', response.test);
    localStorage.setItem('mail', response.mail);
    localStorage.setItem('company', response.company);
    localStorage.setItem('test_category', response.test_category);
    localStorage.setItem('reply_mail', response.reply_mail);
    localStorage.setItem('cc_mail', response.cc_mail);
    return true;
  }
}

export default new AuthService();
