import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import "./_style.scss";
import '../email/email.css';
import'../../App.css';
import logo from '../../zayogro.png';
import AuthService from "./AuthService";
import axios from'axios';
import CookieService from "./CookieService";
import { Col, Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import UrlService from "./UrlService";


class Details extends Component<RouteComponentProps> {

    constructor() {
        super();
        this.state = {
        salutation:"",    
        first: localStorage.getItem('uname1'),
        middle: localStorage.getItem('uname2'),
        last: localStorage.getItem('uname3'),
        // company: "",
        gender:'male',
        age: "",
        selectedCountry : '--Choose Country--',
        selectedState : '--Choose State--',
        selecteddepfun:'--Choose Department--',
        city:"",
        department: "",
        designation:"",
        totalexp: "",
        currentexp: "",
        expincurrent:"",
        testpur: "",
        relocation:"",
        career:'',
        career1:'',
        career2:'',
        isChecked: false,
        depval: [],
        conval: [],
        staval: [],
        citval: [],
        depfunval: [],
        desgval: [],
        relocate:''
        
        };
        this.changeCountry = this.changeCountry.bind(this);
        this.changeState = this.changeState.bind(this);
        this.changedepfun = this.changedepfun.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChange1 = this.handleChange1.bind(this);
        this.handleChange2 = this.handleChange2.bind(this);
    }


    componentDidMount() {


         const dep = {

            company:localStorage.getItem('company')
         }
         
         

         axios.put(UrlService.depUrl(),dep)
         .then(response=>{
            this.setState({
                            depval: response.data,
                        })
                    // console.log(response);      
            })

            axios.put(UrlService.desgUrl(),dep)
            .then(response=>{
               this.setState({
                               desgval: response.data,
                           })
                       // console.log(response);      
               })

            



            axios.get(UrlService.ConUrl(),dep)
            .then(response=>{
               this.setState({
                               conval: response.data,
                           })
                       // console.log(response);      
               })


    }

    changeCountry(event) {
        this.setState({selectedCountry: event.target.value});
        const con = {

            country:event.target.value,
            
         }

           axios.get(UrlService.StaUrl(),{
            params: {
                country_id:event.target.value
            }})
           .then(response=>{
              this.setState({
                              staval: response.data,
                          })
                      console.log(response);      
              })  
       
	}

	changeState(event) {
        this.setState({selectedState: event.target.value});
           axios.get(UrlService.citUrl(),{
            params: {
                state_id:event.target.value
            }})
           .then(response=>{
              this.setState({
                              citval: response.data,
                          })
                    //   console.log(response);      
              })  
		
    }
    
    changedepfun(event){
    this.setState({selecteddepfun: event.target.value});
    const company = localStorage.getItem('company')
        axios.get(UrlService.depfunUrl(),{
            params: {
            department_id:event.target.value,
            company:company

        }})
            .then(response=>{
            this.setState({
                            depfunval: response.data,
                        })
                    // console.log(response);      
            })

    }
   

    async handleFormSubmit(event) {

        event.preventDefault();

        const usid = localStorage.getItem('userid');

        const postData = {
        salutation:this.state.salutation,    
        first:this.state.first,
        middle:this.state.middle,
        last:this.state.last,
        gender:this.state.gender,
        age:this.state.age,
        selectedCountry :this.state.selectedCountry,
        selectedState :this.state.selectedState,
        city:this.state.city,
        department:this.state.department,
        depfun:this.state.depfun,
        designation:this.state.designation,
        totalexp: this.state.totalexp,
        currentexp:this.state.currentexp,
        expincurrent:this.state.expincurrent,
        relocation:this.state.relocate,
        career:this.state.career,
        career1:this.state.career1,
        career2:this.state.career2,
        userid:usid
        };

        
        
        // this.props.history.push("/Storyline");

        axios.post(UrlService.CanUrl(usid),{
        params: {
        salutation:this.state.salutation,    
        first:this.state.first,
        middle:this.state.middle,
        last:this.state.last,
        gender:this.state.gender,
        age:this.state.age,
        selectedCountry :this.state.selectedCountry,
        selectedState :this.state.selectedState,
        city:this.state.city,
        department:this.state.selecteddepfun,
        depfun:this.state.depfun,
        designation:this.state.designation,
        totalexp: this.state.totalexp,
        currentexp:this.state.currentexp,
        expincurrent:this.state.expincurrent,
        relocation:this.state.relocate,
        career:this.state.career,
        career1:this.state.career1,
        career2:this.state.career2,
        userid:usid
            }})
           .then(response=>{

            if(response.status == 200){

                this.props.history.push("/Storyline1");

            }
            //   this.setState({
            //                   res: response.data,
            //               })
                      console.log(response);      
              })  
        
    }

    
    handleChange(event) {
        const value = event.target.value;
         this.setState({
           gender:value,
         
         });
        //  this.draft(value)
       }

    handleChange1(event) {
        const value1 = event.target.value;
         this.setState({
           relocate:value1,
         
         });
        //  this.draft(value)
       } 

    handleChange2(event) {
        const value2 = event.target.value;
        if(value2 == "Mr" || value2 == "Dr"){
            this.setState({
                salutation:value2,
                gender:"male"
              });
        }
        if(value2 == "Mrs" || value2 == "Ms"){
            this.setState({
                salutation:value2,
                gender:"female"
              });
        }
        if(value2 == "Mx"){
            this.setState({
                salutation:value2,
                gender:"other"
              });
        }
        //  this.setState({
        //    salutation:value2,
        //  });
        //  this.draft(value)
       }  


    handleChecked() {
        this.setState({ isChecked: !this.state.isChecked });
    }

    render() {
        const { first, middle, last, career,gender,department,age,totalexp,currentexp,city,designation,expincurrent,relocation,career1,career2} = this.state;
        const usid = localStorage.getItem('userid');
        var{depval} =this.state;
        var{conval} =this.state;
        var{staval} =this.state;
        var{citval} =this.state;
        var{depfunval} =this.state;
        var{desgval} =this.state;
        return (
            <React.Fragment>
<div className="container-fluid" style={{ backgroundColor: "#FFFFFF" }}>
{/* <nav className="navbar navbar-light bg-primary"><img src={logo} className="login-logo-small mt-1 h-vmin"/><p style={{fontWeight:"700",fontSize:"1.5rem"}}>Evolve</p></nav> */}
<nav className="navbar navbar-light  mt-0 "  >
    <img src={logo} className="login-logo-small mt-1 h-5vmin" alt=""></img>
    <p style={{fontWeight:"700",fontSize:"1.7rem",color:"white",textShadow: "4px 4px 8px #000000"}}>E-Volve</p>
    </nav>
    <div className="content-wrapper">
        <div className="email-wrapper wrapper">
            <div className="row align-items-stretch">
              <div className="mail-sidebar d-none d-lg-block col-md-2 pt-3 bg-white">
                    <div className="menu-bar">
                            <ul className="menu-items">
                            {/* <img src={logo} className="login-logo-small mt-3"/> */}
                                <li><i className="mdi mdi-email-outline"></i> Introduction</li>
                                <li className="active"><i className="mdi mdi-share"></i> Personal Details</li>
                                <li><i className="mdi mdi-upload"></i> Storyline</li>
                                <li><i className="mdi mdi-file-outline"></i> Mailbox</li>
                                <li><i className="mdi mdi-star-outline"></i> Thank You</li>
                                {/* <li><a href="#"><i class="mdi mdi-delete"></i> Trash</a></li> */}
                            </ul>                            
                    </div>
              </div>

              <div className="mail-view d-none d-md-block col-lg-9  bg-white mt-5">
                  {localStorage.getItem('test_category') == 1 ? <label className="input-group mb-3" style={{fontSize:"1.5rem",fontWeight:"700"}}>Selection Process</label>
                  :(localStorage.getItem('test_category') == 2 ? <label className="input-group mb-3" style={{fontSize:"1.5rem",fontWeight:"700"}}>Observation Process</label>
                  :<label className="input-group mb-3" style={{fontSize:"1.5rem",fontWeight:"700"}}>Assessment Process</label>)}
              
              <label className="input-group mb-3">Please fill in your details.All Fields are mandatory</label>
                    <form onSubmit={(event) => this.handleFormSubmit(event)}>
                    <div className="row">
                        <div className=" col-lg-6">
                        <label><b>Salutation:</b> </label>
                                    <div className="input-group mb-3">

                                        <select className="form-control" value={this.state.value} onChange={this.handleChange2}
                                        // {(event) =>
                                        //             this.setState({ salutation: event.target.value })}
                                                    >           
                                                        <option></option>
                                                        <option value="Mr">Mr</option>
                                                        <option value ="Mrs">Mrs</option>
                                                        <option value ="Ms">Ms</option>
                                                        <option value ="Mx">Mx</option>
                                                        <option value ="Dr">Dr</option>
                                        </select>
                                    </div> 
                                    <label><b>First Name:</b> </label>
                                    <div className="input-group mb-3">                                    
                                        <input
                                            type="text"
                                            name="first"
                                            className="form-control"    
                                            // placeholder="First"
                                            value={first}
                                            onChange={(event) =>
                                                this.setState({ first: event.target.value })
                                            }
                                        />

                                    </div>
                                    <label><b>Middle Name:</b></label>
                                    <div className="input-group mb-3">                                    
                                        <input
                                            type="text"
                                            name="middle"
                                            className="form-control"
                                            // placeholder="Middle"
                                            value={middle} 
                                            onChange={(event) =>
                                                this.setState({ middle: event.target.value })
                                            }
                                        />

                                    </div> 
                                    <label><b>Last Name:</b></label>
                                    <div className="input-group mb-3">                                    
                                        <input
                                            type="text"
                                            name="last"
                                            className="form-control"
                                            // placeholder="Last"
                                            value={last}
                                            onChange={(event) =>
                                                this.setState({ last: event.target.value })
                                            }
                                        />

                                    </div> 		  
						

                                    <label><b>Gender:</b></label>
                                    <div className="input-group mb-3 row">
                                        

                                        <label className="checkbox-inline ml-5 mr-3" style={{fontSize:"1.20rem",fontWeight:"500"}}><input type="checkbox" checked={this.state.gender === 'male'} className="mr-1" value="male" onChange={this.handleChange} />Male </label>
                                        <label className="checkbox-inline mr-3" style={{fontSize:"1.20rem",fontWeight:"500"}}><input type="checkbox" checked={this.state.gender === 'female'} className="mr-1" value="female" onChange={this.handleChange} />Female </label>
                                        <label className="checkbox-inline mr-3" style={{fontSize:"1.20rem",fontWeight:"500"}}><input type="checkbox" checked={this.state.gender === 'other'} className="mr-1" value="other" onChange={this.handleChange} />Other </label> 

                                        {/* <label class="checkbox-inline"><input type="checkbox" value=""/>Option 3</label> */}

                                    </div>

                                    <label><b>Age:</b></label>
                                    <div className="input-group mb-3">                                    
                                        <input
                                            type="number"
                                            name="age"
                                            className="form-control"
                                            min="1"
                                            // placeholder="Age"
                                            value={age}
                                            onChange={(event) =>
                                                this.setState({ age: event.target.value })
                                            }
                                        />

                                    </div>

                                    <label><b>Country (currently based out of):</b></label>
                                    <div className="input-group mb-3">                                    
                                        <select className="form-control" name="country" value={this.state.selectedCountry} 
                                        // onChange={(event) =>
                                        //         this.setState({ country: event.target.value })}
                                        onChange={this.changeCountry}
                                                >           
                                                    <option></option>
                                                    {conval.map(item=>(
                                                    <option value={item.id} key={item.id}>{item.name}</option>
                                                    ))}
                                    </select>

                                    </div>

                                    <label><b>State (currently based out of):</b></label>
                                    <div className="input-group mb-3">                                    
                                        <select className="form-control" name="state" value={this.state.selectedState} 
                                    
                                    onChange={this.changeState}
                                                >           
                                                    <option></option>
                                                    {staval.map(item=>(
                                                    <option value={item.id} key={item.id}>{item.name}</option>
                                                    ))}
                                    </select>

                                    </div>

                                    <label><b>City (currently based out of):</b></label>
                                <div className="input-group mb-3">                               
                                    <select className="form-control" name="city" value={this.state.value} 
                                    onChange={(event) =>
                                            this.setState({ city: event.target.value })}>           
                                                <option></option>
                                                {citval.map(item=>(
                                                <option value={item.id} key={item.id}>{item.name}</option>
                                                ))}
                                </select>

                                </div>
                        </div>
                            
                            <div className="col-lg-6">
                            <label><b>Department</b></label>
                                <div className="input-group mb-3">
                                    <select className="form-control" name="department" value={this.state.selecteddepfun} 
                                    // onChange={(event) =>
                                    //         this.setState({ department: event.target.value })}> 
                                    onChange={this.changedepfun}>           
                                                <option></option>
                                                {depval.map(item=>(
                                                <option value={item.id} key={item.id}>{item.name}</option>
                                                ))}
                                </select>

                                </div>

                                <label><b>Department Function:</b></label>
                                <div className="input-group mb-3">                                
                                    <select className="form-control" name="depfun" value={this.state.value} onChange={(event) =>
                                            this.setState({ depfun: event.target.value })}>           
                                                <option></option>
                                                {depfunval.map(item=>(
                                                <option value={item.id} key={item.id}>{item.name}</option>
                                                ))}
                                </select>
                                </div>

                                <label><b>Current Designation:</b></label>
                                {localStorage.getItem('test_category') == 2 ?  <div className="input-group mb-3">
                                
                                    <select className="form-control" name="designation" value={this.state.value} onChange={(event) =>
                                            this.setState({ designation: event.target.value })}>           
                                                <option></option>
                                                {desgval.map(item=>(
                                                <option value={item.id} key={item.id}>{item.name}</option>
                                                ))}
                                </select>
                                </div>
                                :
                                <div className="input-group mb-3">
                                    
                                <input
                                    type="text"
                                    name="designation"
                                    className="form-control"
                                    // placeholder="Designation"
                                    value={designation}
                                    onChange={(event) =>
                                        this.setState({ designation: event.target.value })
                                    }
                                />

                                </div> 
                                }


                                <label><b>Total Experience (Yrs):</b></label>
                                <div className="input-group mb-3">                                
                                    <input
                                        type="number"
                                        name="totalexp"
                                        className="form-control"
                                        min="0"
                                        // placeholder="Total Experience"
                                        value={totalexp}
                                        onChange={(event) =>
                                            this.setState({ totalexp: event.target.value })
                                        }
                                    />

                                </div>

                                <label><b>Current Experience (Yrs):</b></label>
                                <div className="input-group mb-3">
                                
                                    <input
                                        type="number"
                                        name="currentexp"
                                        className="form-control"
                                        min="0"
                                        // placeholder="Current Experience"
                                        value={currentexp}
                                        onChange={(event) =>
                                            this.setState({ currentexp: event.target.value })
                                        }
                                    />
                                </div>
                            
                      
                                <label><b>Experience in Current Position (Yrs):</b></label>
                                <div className="input-group mb-3">                                
                                    <input
                                        type="number"
                                        name="expincurrent"
                                        className="form-control"
                                        min="0"
                                        // placeholder="Experience In Current Position"
                                        value={expincurrent}
                                        onChange={(event) =>
                                            this.setState({ expincurrent: event.target.value })
                                        }
                                    />
                                </div>

                                
                                    <div className="input-group mb-3 row">
                                    <label className="ml-3"><b>Willing To Relocate:</b></label>
                                    <label className="checkbox-inline ml-5 mr-3" style={{fontSize:"1.20rem",fontWeight:"500"}}><input type="checkbox" checked={this.state.relocate === 'Yes'} className="mr-1" value="Yes" onChange={this.handleChange1} />Yes </label>
                                    <label className="checkbox-inline mr-3" style={{fontSize:"1.20rem",fontWeight:"500"}}><input type="checkbox" checked={this.state.relocate === 'No'} className="mr-1" value="No" onChange={this.handleChange1} />No </label>


                                    </div>
                                    <label><b>Career Aspiration (0-2 Years)</b></label>
                                    <div className="input-group mb-3">
                                <textarea
                                    // type="textarea"
                                    name="career"
                                    className="form-control"
                                    // placeholder="userid"
                                    value={career}
                                    onChange={(event) =>
                                        this.setState({ career: event.target.value })
                                    }
                                />
                                </div>
                                <label><b>Career Aspiration(2-5 Years)</b></label>
                                    <div className="input-group mb-3">
                                <textarea
                                    // type="textarea"
                                    name="career1"
                                    className="form-control"
                                    // placeholder="userid"
                                    value={career1}
                                    onChange={(event) =>
                                        this.setState({ career1: event.target.value })
                                    }
                                />
                                </div>
                                <label><b>Career Aspiration(5 Years and Above)</b></label>
                                    <div className="input-group mb-3">
                                <textarea
                                    // type="textarea"
                                    name="career2"
                                    className="form-control"
                                    // placeholder="userid"
                                    value={career2}
                                    onChange={(event) =>
                                        this.setState({ career2: event.target.value })
                                    }
                                />

                            </div>

                                <div className="input-group mb-3">
                                <input
                                    type="hidden"
                                    name="userid"
                                    className="form-control"
                                    placeholder="userid"
                                    value={usid}
                                    // onChange={(event) =>
                                    //     this.setState({ firstname: event.target.value })
                                    // }
                                />

                            </div>
                                

                                    
                            </div>
                        </div>
                                <div className="col-lg-3">
                                <div className="row">
                                    
                                <button
                                            type="submit"
                                            className="btn btn-primary btn-block">
                                            Submit
                                    </button>
                                </div>
                            </div>
                    </form>

       
              </div>
              
            </div>
        </div>
    </div>
</div>
            </React.Fragment>
        );
    }
}

export default Details;
