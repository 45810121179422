import React, { Component, Props } from "react";
import "./email.css";
import Timer from "react-compound-timer";
import avatar from "./avatar.png";
import reply from "./reply.png";
import cc from "./cc.png";
import logo from "../../zayogro.png";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
  InputGroup,
  InputGroupAddon,
  ButtonGroup
} from "reactstrap";
import axios from "axios";
import UrlService from "../details/UrlService";
import { Container, Row, Nav, NavItem, Navbar,Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,ModalTitle } from "react-bootstrap";
import BootBox from "react-bootbox";
import { data } from "jquery";
import SearchField from "react-search-field";
import FlashMessage from "react-flash-message";
import soundfile from "./Countdown-timer.mp3";

class Mail extends Component<Props> {
  constructor(props) {
    super(props);
    // this.handleShow = this.handleShow.bind(this);
    // this.handleClose = this.handleClose.bind(this);
    this.state = {
      mailLists: [],
      inboxList: [],
      sendList: [],
      draftList: [],
      mailContent: [],
      loadList: [],
      subquestion: [],
      supersubquestion: [],
      subquestionList: [],
      supersubquestionList: [],
      parents: 0,
      superparent: 0,
      modal: false,
      data: [],
      show: false,
      show1: false,
      loadtm: "",
      pretm: "",
      showreply: false,
      showcc: false,
      alert: false,
      selected: null,
      done: true,
      style: "bold",
      questid: "",
      qid: "",
      questidnew: "",
      option: "",
      space: "",
      read: 0,
      box: "inbox",
      active: "false",
      press: "",
      postList: [],
      isOldestFirst: true,
      isoldestfirst: true,
      search: " ",
      general: [],
      department: [],
      generalList: [],
      departmentList: [],
      responseList: [],
      superresponseList: [],
      slot5: [],
      slot6: [],
      slot7: [],
      timer: null,
      sortstatus: "",
      flash: "",
    };
    this.handleChange = this.handleChange.bind(this);

    this.handleOnSubmit = this.handleOnSubmit.bind(this);

    this.updateContent = this.updateContent.bind(this);

    this.toggleSortDateAsc = this.toggleSortDateAsc.bind(this);

    this.toggleSortDateDsc = this.toggleSortDateDsc.bind(this);

    this.toggleListReverse = this.toggleListReverse.bind(this);

    this.searchSpace = this.searchSpace.bind(this);

    this.input = React.createRef();
  }

  componentDidMount() {
    this.getdata();

    this.timer = setInterval(() => this.getdata1(), 2000);

    // this.timer1 = setInterval(()=> this.getdata2(), 300000)

    // this.timer2 = setInterval(()=> this.getdata3(), 600000)

    // this.timer3 = setInterval(()=> this.getdata4(), 780000)

    // this.timer4 = setInterval(()=> this.getdata5(), 1140000)

    // this.timer5 = setInterval(()=> this.getdata6(), 1320000)

    // this.timer6 = setInterval(()=> this.getdata7(), 1500000)

    this.timer1 = setInterval(() => this.getdata2(), 60000);

    this.timer2 = setInterval(() => this.getdata3(), 120000);

    this.timer3 = setInterval(() => this.getdata4(), 180000);

    this.timer4 = setInterval(() => this.getdata5(), 240000);

    this.timer5 = setInterval(() => this.getdata6(), 300000);

    this.timer6 = setInterval(() => this.getdata7(), 360000);
  }

  // componentWillUpdate() {
    // this.timer = setInterval(()=> this.getdata(), 60000)
    // this.timer1 = setInterval(()=> this.getdata2(), 180000)
    // clearTimeout(this.timer);
    // clearInterval(this.state.timer);
    // clearInterval(this.timer);
    // this.timer1 = setInterval(()=> this.getdata2(), 60000)
    // this.timer2 = setInterval(()=> this.getdata3(), 120000)
  // }

  // componentWillUnmount() {
  //     clearInterval(this.timer);
  //   }

  componentDidUpdate() {
    // this.timer = setInterval(()=> this.getdata(), 60000)
    // this.timer1 = setInterval(()=> this.getdata2(), 60000)
    // clearTimeout(this.timer1);
    // this.timer2 = setInterval(()=> this.getdata3(), 120000)
    // clearTimeout(this.timer2);
    // clearInterval(this.timer);
  }

  getdata() {
    const test = localStorage.getItem("test");
    //fetch("http://127.0.0.1:8000/api/test/" + test)
	fetch(UrlService.TestUrl(test))
	
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          mailLists: json.department1,
          general: json.general,
          department: json.department1,
          subquestion: json.subquestion,
          supersubquestion: json.supersubquestion,
          // loadtm:new Date().toLocaleString(),
          // loadList:json
        });
      })
      .catch((err) => {
        console.log(err);
      });
      // clearInterval(this.timer);
  }

  getdata1() {
    // this.randomize(this.state.mailLists,8);

    if (this.state.general.length > this.state.department.length) {
      this.randomize1(this.state.general, 6);
      this.randomize2(this.state.department, 2);
    } else {
      this.randomize1(this.state.general, 2);
      this.randomize2(this.state.department, 6);
    }

    this.setState({
      inboxList: this.state.inboxList.concat(
        this.state.generalList.concat(this.state.departmentList)
      ),
      // loadList:inboxList
    });

    let newPostList = this.state.inboxList;

    newPostList = this.state.inboxList.sort((a, b) => a.time < b.time);

    this.setState({
      isOldestFirst: !this.state.isOldestFirst,
      loadList: newPostList,
      inboxlist: newPostList,
      // flash: this.state.inboxList.length + " New Mails have arrived",
      sortstatus: "desc",
        // loadList: this.state.inboxlist,
    });

    clearInterval(this.timer);
    this.state.flash = "";
  }

  getdata2() {
    this.setState({
      generalList: [],
      departmentList: [],
      // loadList:inboxList
    });

    // this.randomize(this.state.mailLists,3);

    if (this.state.general.length > this.state.department.length) {
      this.randomize1(this.state.general, 2);
      this.randomize2(this.state.department, 1);
    } else {
      this.randomize1(this.state.general, 1);
      this.randomize2(this.state.department, 2);
    }

    // const extra = this.state.subquestion.concat(this.state.supersubquestion);

    this.setState({
      inboxList: this.state.inboxList.concat(
        this.state.generalList
          .concat(this.state.departmentList)
          .concat(this.state.subquestionList)
          .concat(this.state.supersubquestionList)
      ),
      // loadList:inboxList
      //   extra = this.state.subquestion.concat(this.state.supersubquestion),
    });

    let newPostList = this.state.inboxList;

    newPostList = this.state.inboxList.sort((a, b) => a.time < b.time);

    const count =
      this.state.departmentList.length +
      this.state.generalList.length +
      this.state.subquestionList.length +
      this.state.supersubquestionList.length;

    this.setState({
      isOldestFirst: !this.state.isOldestFirst,
      loadList: newPostList,
      inboxlist: newPostList,

      flash: count + " New Mails have arrived",
      sortstatus: "desc",
      //   loadList: this.state.inboxlist,
      subquestionList: [],
      supersubquestionList: [],
    });

    clearInterval(this.timer1);
    this.state.flash = "";
  }

  getdata3() {
    // this.randomize(this.state.mailLists,3);

    this.setState({
      generalList: [],
      departmentList: [],
      count: "",
      // loadList:inboxList
    });

    if (this.state.general.length > this.state.department.length) {
      this.randomize1(this.state.general, 2);
      this.randomize2(this.state.department, 1);
    } else {
      this.randomize1(this.state.general, 1);
      this.randomize2(this.state.department, 2);
    }

    this.setState({
      inboxList: this.state.inboxList.concat(
        this.state.generalList
          .concat(this.state.departmentList)
          .concat(this.state.subquestionList)
          .concat(this.state.supersubquestionList)
      ),
      // loadList:inboxList
    });

    let newPostList = this.state.inboxList;

    newPostList = this.state.inboxList.sort((a, b) => a.time < b.time);

    const count =
      this.state.departmentList.length +
      this.state.generalList.length +
      this.state.subquestionList.length +
      this.state.supersubquestionList.length;

    this.setState({
      isOldestFirst: !this.state.isOldestFirst,
      loadList: newPostList,
      inboxlist: newPostList,
      flash: count + " New Mails have arrived",
      sortstatus: "desc",
      //   loadList: this.state.inboxlist,
      subquestionList: [],
      supersubquestionList: [],
    });

    clearInterval(this.timer2);
    this.state.flash = "";
  }

  getdata4() {
    // this.randomize(this.state.mailLists,3);

    this.setState({
      generalList: [],
      departmentList: [],
      count: "",
      // loadList:inboxList
    });

    if (this.state.general.length > this.state.department.length) {
      this.randomize1(this.state.general, 2);
      this.randomize2(this.state.department, 1);
    } else {
      this.randomize1(this.state.general, 1);
      this.randomize2(this.state.department, 2);
    }

    this.setState({
      inboxList: this.state.inboxList.concat(
        this.state.generalList
          .concat(this.state.departmentList)
          .concat(this.state.subquestionList)
          .concat(this.state.supersubquestionList)
      ),
      // loadList:inboxList
    });

    let newPostList = this.state.inboxList;

    newPostList = this.state.inboxList.sort((a, b) => a.time < b.time);

    const count =
      this.state.departmentList.length +
      this.state.generalList.length +
      this.state.subquestionList.length +
      this.state.supersubquestionList.length;

    this.setState({
      isOldestFirst: !this.state.isOldestFirst,
      loadList: newPostList,
      inboxlist: newPostList,
      flash: count + " New Mails have arrived",
      sortstatus: "desc",
      //   loadList: this.state.inboxlist,
      subquestionList: [],
      supersubquestionList: [],
    });

    clearInterval(this.timer3);
    this.state.flash = "";
  }

  getdata5() {
    // this.randomize(this.state.mailLists,3);

    this.setState({
      generalList: [],
      departmentList: [],
      count: "",
      // loadList:inboxList
    });

    if (this.state.general.length > this.state.department.length) {
      this.randomize1(this.state.general, 2);
      this.randomize2(this.state.department, 1);
    } else {
      this.randomize1(this.state.general, 1);
      this.randomize2(this.state.department, 2);
    }

    this.setState({
      inboxList: this.state.inboxList.concat(
        this.state.generalList
          .concat(this.state.departmentList)
          .concat(this.state.subquestionList)
          .concat(this.state.supersubquestionList)
      ),
      // loadList:inboxList
    });

    let newPostList = this.state.inboxList;

    newPostList = this.state.inboxList.sort((a, b) => a.time < b.time);

    const count =
      this.state.departmentList.length +
      this.state.generalList.length +
      this.state.subquestionList.length +
      this.state.supersubquestionList.length;

    this.setState({
      isOldestFirst: !this.state.isOldestFirst,
      loadList: newPostList,
      inboxlist: newPostList,
      flash: count + " New Mails have arrived",
      sortstatus: "desc",
      //   loadList: this.state.inboxlist,
      subquestionList: [],
      supersubquestionList: [],
    });

    clearInterval(this.timer4);
    this.state.flash = "";
  }

  getdata6() {
    // this.randomize(this.state.mailLists,3);

    this.setState({
      generalList: [],
      departmentList: [],
      count: "",
      // loadList:inboxList
    });

    if (this.state.general.length > this.state.department.length) {
      this.randomize1(this.state.general, 2);
      this.randomize2(this.state.department, 1);
    } else {
      this.randomize1(this.state.general, 1);
      this.randomize2(this.state.department, 2);
    }

    this.setState({
      inboxList: this.state.inboxList.concat(
        this.state.generalList
          .concat(this.state.departmentList)
          .concat(this.state.subquestionList)
          .concat(this.state.supersubquestionList)
      ),
      // loadList:inboxList
    });

    let newPostList = this.state.inboxList;

    newPostList = this.state.inboxList.sort((a, b) => a.time < b.time);

    const count =
      this.state.departmentList.length +
      this.state.generalList.length +
      this.state.subquestionList.length +
      this.state.supersubquestionList.length;

    this.setState({
      isOldestFirst: !this.state.isOldestFirst,
      loadList: newPostList,
      inboxlist: newPostList,
      flash: count + " New Mails have arrived",
      sortstatus: "desc",
      //   loadList: this.state.inboxlist,
      subquestionList: [],
      supersubquestionList: [],
    });

    clearInterval(this.timer5);
    this.state.flash = "";
  }

  getdata7() {
    // this.randomize(this.state.mailLists,3);

    this.setState({
      generalList: [],
      departmentList: [],
      count: "",
      // loadList:inboxList
    });

    if (this.state.general.length > this.state.department.length) {
      this.randomize1(this.state.general, 1);
      this.randomize2(this.state.department, 1);
    } else {
      this.randomize1(this.state.general, 1);
      this.randomize2(this.state.department, 1);
    }

    this.setState({
      inboxList: this.state.inboxList.concat(
        this.state.generalList
          .concat(this.state.departmentList)
          .concat(this.state.subquestionList)
          .concat(this.state.supersubquestionList)
      ),
      // loadList:inboxList
    });

    let newPostList = this.state.inboxList;

    newPostList = this.state.inboxList.sort((a, b) => a.time < b.time);

    const count =
      this.state.departmentList.length +
      this.state.generalList.length +
      this.state.subquestionList.length +
      this.state.supersubquestionList.length;

    this.setState({
      isOldestFirst: !this.state.isOldestFirst,
      loadList: newPostList,
      inboxlist: newPostList,
      flash: count + " New Mails have arrived",
      sortstatus: "desc",
      //   loadList: this.state.inboxlist,
      subquestionList: [],
      supersubquestionList: [],
    });

    clearInterval(this.timer6);
    this.state.flash = "";
  }

  randomize(array, n) {
    // making copy to do not affect original srcArray
    this.state.mailLists = this.state.mailLists.slice();
    let resultArr = [];
    // while srcArray isn't empty AND we didn't enough random elements
    while (this.state.mailLists.length && resultArr.length < n) {
      // remove one element from random position and add this element to the result array
      resultArr = resultArr.concat(
        // merge arrays
        this.state.mailLists.splice(
          // extract one random element
          Math.floor(Math.random() * this.state.mailLists.length),
          1
        )
      );
    }

    this.setState({
      inboxList: this.state.inboxList.concat(resultArr),
      loadtm: new Date().toLocaleString(),
      // loadList:this.state.inboxList.concat(newPostList)
    });

    // const {resultArr} = this.state
    let newPostList = this.state.inboxList;

    newPostList = this.state.inboxList.sort((a, b) => a.time < b.time);

    this.setState({
      isOldestFirst: !this.state.isOldestFirst,
      loadList: newPostList,
      inboxlist: newPostList,
      flash: n + " New Mails have arrived",
      sortstatus: "desc",
      //   loadList: this.state.inboxlist,
    });
  }

  randomize1(array, n) {
    // making copy to do not affect original srcArray
    this.state.general = this.state.general.slice();
    let resultArr = [];
    // while srcArray isn't empty AND we didn't enough random elements
    while (this.state.general.length && resultArr.length < n) {
      // remove one element from random position and add this element to the result array
      resultArr = resultArr.concat(
        // merge arrays
        this.state.general.splice(
          // extract one random element
          Math.floor(Math.random() * this.state.general.length),
          1
        )
      );
    }

    this.setState({
      generalList: this.state.generalList.concat(resultArr),
      // inboxList:this.state.inboxList.concat(resultArr),
      loadtm: new Date().toLocaleString(),
      // loadList:this.state.inboxList.concat(newPostList)
    });
  }

  randomize2(array, n) {
    // making copy to do not affect original srcArray
    this.state.department = this.state.department.slice();
    let resultArr = [];
    // while srcArray isn't empty AND we didn't enough random elements
    while (this.state.department.length && resultArr.length < n) {
      // remove one element from random position and add this element to the result array
      resultArr = resultArr.concat(
        // merge arrays
        this.state.department.splice(
          // extract one random element
          Math.floor(Math.random() * this.state.department.length),
          1
        )
      );
    }

    this.setState({
      departmentList: this.state.departmentList.concat(resultArr),
      // inboxList:this.state.inboxList.concat(resultArr),
      loadtm: new Date().toLocaleString(),
      // loadList:this.state.inboxList.concat(newPostList)
    });
  }

  addZero(i) {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
  }

  updateContent(id) {
    this.setState({
      mailContent: id.id,
      pretm: new Date().toLocaleString(),
      showreply: false,
      showcc: false,
      selected: id.id,
      questid: id.id,
      clicked: id,
      option: "",
      // active: true
    });

    if (id.read != 0 && this.state.box != "search") {
      this.changeclick(id);
    }

    if (id.read != 0 && this.state.box == "search") {
      this.changeclick1(id);
    }

    if (id.sent != 0 && id.draft != 0 && id.press == 1 && id.reply == 1) {
      this.state.inboxList.map(function (item) {
        if (item.id == id.id) {
          item.reply = 0;
          id.press = 0;
          item.parent = "";
        }
      });
    }

    if (id.sent != 0 && id.draft != 0 && id.cc == 1) {
      this.state.inboxList.map(function (item) {
        if (item.id == id.id) {
          item.reply = 0;
          id.press = 0;
          item.parent = "";
        }
      });
    }

    if (
      id.option != "" &&
      this.state.box == "draft" &&
      (id.replymail == 1 || id.reply == 1)
    ) {
      const selection1 = id.option;
      this.setState({
        showreply: true,
        option: selection1,
      });
    }

    if (
      id.option != "" &&
      this.state.box == "draft" &&
      (id.ccmail == 1 || id.cc == 1)
    ) {
      const selection1 = id.option;
      this.setState({
        showcc: true,
        option: selection1,
      });
    }

    if (id.option != "" && this.state.box == "sent" && id.replymail == 1) {
      const selection = id.option;
      this.setState({
        showreply: true,
        option: selection,
      });
    }

    if (id.option != "" && this.state.box == "sent" && id.ccmail == 1) {
      const selection = id.option;
      this.setState({
        showcc: true,
        option: selection,
      });
    }
  }

  changeclick(id) {
    this.state.inboxList.map(function (item) {
      if (item.id == id.id) {
        item.read = 0;
        item.option = "";
      }
    });

    this.setState({
      loadList: this.state.inboxList,
      read: this.state.read + 1,
    });

    //   console.log(this.state.mailLists);
    console.log(this.state.loadList);
  }

  changeclick1(id) {
    this.state.inboxList.map(function (item) {
      if (item.id == id.id) {
        item.read = 0;
        item.option = "";
      }
    });

    this.setState({
      // loadList: this.state.searchlist,
      read: this.state.read + 1,
    });

    //   console.log(this.state.mailLists);
    console.log(this.state.loadList);
  }

  handleShow = (info) => {
    this.setState({
      show: true,
      data: info,
      questid: data.id,
    });
  };

  handleClose = (fromModal) => {
    // alert(fromModal.msg);

    this.setState({
      show: false,
    });
  };

  sentShow(mail) {
    this.setState({
      mailContent: "",
      // pretm:new Date().toLocaleString(),
      // showreply: false,
      // showcc: false,
      selected: mail.id,
      // questid:id.id,
      // clicked:id,
      // option:'',
      // active: true
      loadList: this.state.sendList,
      //   mailContent:'',
      box: "sent",
    });

    if (mail.replymail == 1) {
      this.setState({
        mailContent: "",
        showreply: true,
        selected: mail.id,
        option: mail.option,
        // loadList: this.state.sendList,
        mailContent: mail.id,
        box: "sent",
      });
    }

    if (mail.ccmail == 1) {
      this.setState({
        mailContent: "",
        showcc: true,
        selected: mail.id,
        option: mail.option,
        // loadList: this.state.sendList,
        mailContent: mail.id,
        box: "sent",
      });
    }
  }

  handleChange(event) {
    const value = event.target.value;
    this.setState({
      option: event.target.value,
    });
    this.draft(value);
  }

  draft(optval) {
    const array = this.state.selected;
    //    let arrval = [];

    var today = new Date(),
      time = today.getHours() + ":" + this.addZero(today.getMinutes());

    this.state.inboxList.map(function (item) {
      if (item.id == array) {
        // item.read = 0;
        item.option = optval;
        item.draft = 0;
        item.selcount = item.selcount + 1;
        item.drafttime = time;
      }
    });

    let arrval = this.state.inboxList.filter((x) => x.id == array);

    if (arrval[0].selcount == 1) {
      this.setState({
        // loadList: this.state.mailLists,
        // read:this.state.read + 1,
        draftList: this.state.draftList.concat(arrval),
      });
    }

    //   console.log(this.state.mailLists);
    console.log(this.state.loadList);
  }

  discard() {
    const click = this.state.selected;

    //const confirm = '';

    if (
      window.confirm(
        "You have opted to remove your changes. Are you sure you still want to discard this mail?"
      )
    ) {
      this.state.inboxList.map(function (item) {
        if (item.id == click) {
          item.draft = 1;
          item.reply = 0;
          item.option = "";
          item.press = 0;
        }
      });

      if (this.state.box == "draft") {
        this.setState({
          option: "",
          showreply: !this.state.showreply,
          draftList: this.state.draftList.filter((x) => x.id != click),
          // loadList: this.state.draftList,
          press: "",
        });
        if (this.state.draftList.length == 1) {
          this.setState({
            loadList: this.state.draftList.splice((x) => x.id == click),
          });
        }

        this.draftload();

        this.setState({
          loadList: this.state.draftList,
        });
      } else {
        this.setState({
          option: "",
          showreply: !this.state.showreply,
          draftList: this.state.draftList.filter((x) => x.id != click),
          // loadList: this.state.draftList,
        });
      }
    } else {
      // this.setState({showreply:!this.state.showreply})
    }
  }

  discardcc() {
    const click = this.state.selected;

    const draftList1 = [];

    //const confirm = '';

    if (
      window.confirm(
        "You have opted to remove your changes. Are you sure you still want to discard this mail?"
      )
    ) {
      this.state.inboxList.map(function (item) {
        if (item.id == click) {
          item.draft = 1;
          item.cc = 0;
          item.press = 0;
          item.option = "";
        }
      });

      if (this.state.box == "draft") {
        this.setState({
          option: "",
          showcc: !this.state.showcc,
          draftList: this.state.draftList.filter((x) => x.id != click),
          // draftList: this.state.draftList,
          // selected:'',
          press: "",
        });

        if (this.state.draftList.length == 1) {
          this.setState({
            loadList: this.state.draftList.splice((x) => x.id == click),
          });
        }

        this.draftload();

        this.setState({
          loadList: this.state.draftList,
        });
      } else {
        this.setState({
          option: "",
          showcc: !this.state.showcc,
          draftList: this.state.draftList.filter((x) => x.id != click),
          // loadList: this.state.draftList,
        });
      }
    } else {
      // this.setState({showreply:!this.state.showreply})
    }
  }

  handleChanges(event) {
    const questid = event.target.value;
    this.setState({
      questidnew: questid,
    });
    console.log(questid);
  }

  sendload(event) {
    //   const loadList = [];
    this.setState({
      loadList: this.state.sendList,
      mailContent: "",
      box: "sent",
      selected: "",
    });
    //   this.updateContent()
  }

  inboxload(event) {
    this.setState({
      loadList: this.state.inboxList,
      mailContent: "",
      box: "inbox",
      selected: "",
    });
  }

  draftload(event) {
    this.setState({
      loadList: this.state.draftList,
      mailContent: "",
      box: "draft",
      selected: "",
    });
  }

  showreply() {
    this.setState({
      showreply: !this.state.showreply,
      // press:'true'
    });

    const set = this.state.selected;

    this.state.inboxList.map(function (item) {
      if (item.id == set) {
        item.reply = 1;
        item.press = 1;
        item.selcount = 0;
      }
    });
  }

  showcc() {
    this.setState({
      showcc: !this.state.showcc,
      // press:'true'
    });
    const set1 = this.state.selected;

    this.state.inboxList.map(function (item) {
      if (item.id == set1) {
        item.cc = 1;
        item.press = 1;
        item.selcount = 0;
      }
    });
  }

  async handleOnSubmit(event) {
    event.preventDefault();

    const inputvalues = {
      option: this.state.option,
      uid: localStorage.getItem("userid"),
      tid: localStorage.getItem("test"),
      qid: this.state.questid,
      anstm: new Date().toLocaleString(),
      pretm: this.state.pretm,
      loadtm: this.state.loadtm,
    };

    await axios
      .post(UrlService.testAttemptUrl(), inputvalues)
      .then((response) => {
        console.log(response);
      })
      .then((error) => {
        console.log(error);
      });

    // const arr =this.state.mailLists.filter(x => x.id == this.state.questid);

    const arr = this.state.clicked;

    var today = new Date(),
      time = today.getHours() + ":" + this.addZero(today.getMinutes());

    this.state.inboxList.map(function (item) {
      if (item.id == arr.id) {
        item.answer = 0;
        item.option = inputvalues.option;
        item.sent = 0;
        item.draft = 1;
        item.sendtime = time;

        if (item.reply == 1) {
          item.replymail = 1;
        }
        if (item.cc == 1) {
          item.ccmail = 1;
        }
      }
    });

    if (arr.sub_question == 1) {
      this.setState({
        subquestionList: this.state.subquestion.filter(
          (x) => x.mquestion_id === arr.id && x.manswer === this.state.option
        ),
        //   .concat(arr),
        mailContent: "",
        selected: "",
        // responseList: arr,
        // responseList: this.state.subquestion
        //   .filter(
        //     (x) => x.mquestion_id === arr.id && x.manswer === this.state.option
        //   )
        //   .concat(arr),
      });

      this.state.subquestionList.map(function (item) {
        if (item.mquestion_id == arr.id) {
          item.time = time;
          //   item.question = item.question.concat("hello");
          item.parents = arr;
          item.parentflag = 1;
        }
        //  else {
        //   item.parents = [];
        // }
      });

      this.setState({
        // parents: this.state.parents + 1,
        responseList: this.state.responseList.concat(arr),
      });
    }

    if (arr.super_sub_question == 1) {
      this.setState({
        supersubquestionList: this.state.supersubquestion.filter(
          (x) =>
            x.subquestion_id === arr.id && x.subanswer === this.state.option
        ),
        mailContent: "",
        selected: "",
      });
      // this.state.subquestionList.map(function (item) {
      //   if (item.mquestion_id == arr.id) {
      //     item.time = time;
      //   }
      // });

      this.state.supersubquestionList.map(function (item) {
        if (item.mquestion_id == arr.mquestion_id) {
          item.time = time;
          //   item.question = item.question.concat("hello");
          item.superparents = arr;
          item.superparentflag = 1;
        }
        //  else {
        //   item.parents = [];
        // }
      });

      this.setState({
        // parents: this.state.parents + 1,
        superresponseList: this.state.superresponseList.concat(arr),
      });
    }

    this.setState({
      loadList: this.state.inboxList,
    });

    this.setState({
      sendList: this.state.sendList.concat(arr),
      showreply: false,
      showcc: false,
      option: "",
      draftList: this.state.draftList.filter((x) => x.id != arr.id),
      box: "inbox",
    });
  }

  // event.target.value

  showAlert = () => {
    this.props.history.push("/thanks1");
    // alert('Yes is clicked');
  };

  handleClosenew = () => {
    this.setState({ show: false });
    this.props.history.push("/thanks1");
  };

  showAlert1 = () => {
    this.props.history.push("/thanks1");
    // alert('Yes is clicked');
  };

  handleClosenew1 = () => {
    this.setState({ show1: false });
    // this.props.history.push("/thanks");
  };



  toggleSortDateAsc(event) {
    this.asc();
  }

  toggleSortDateDsc(event) {
    this.dsc();
  }

  toggleListReverse(event) {
    this.dsc1();
  }

  asc() {
    if (this.state.box == "inbox") {
      const { loadList } = this.state;
      let newPostList = loadList;
      newPostList = loadList.sort((a, b) => a.time > b.time);

      this.setState({
        //   isOldestFirst: !this.state.isOldestFirst,
        loadList: newPostList,
        sortstatus: "asc",
      });
    }
    if (this.state.box == "sent") {
      const { loadList } = this.state;
      let newPostList = loadList;
      newPostList = loadList.sort((a, b) => a.sendtime > b.sendtime);

      this.setState({
        //   isOldestFirst: !this.state.isOldestFirst,
        loadList: newPostList,
        sortstatus: "asc",
      });
    }
    if (this.state.box == "draft") {
      const { loadList } = this.state;
      let newPostList = loadList;
      newPostList = loadList.sort((a, b) => a.drafttime > b.drafttime);

      this.setState({
        //   isOldestFirst: !this.state.isOldestFirst,
        loadList: newPostList,
        sortstatus: "asc",
      });
    }
  }

  dsc() {
    if (this.state.box == "inbox") {
      const { loadList } = this.state;
      let newPostList1 = loadList;   
      newPostList1 = loadList.sort((a, b) => a.time < b.time);
      this.setState({
        // isoldestfirst: !this.state.isoldestfirst,
        loadList: newPostList1,
        sortstatus: "desc",
      });
    }
    if (this.state.box == "sent") {
      const { loadList } = this.state;
      let newPostList1 = loadList;
      newPostList1 = loadList.sort((a, b) => a.sendtime < b.sendtime);
      this.setState({
        // isoldestfirst: !this.state.isoldestfirst,
        loadList: newPostList1,
        sortstatus: "desc",
      });
    }
    if (this.state.box == "draft") {
      const { loadList } = this.state;
      let newPostList1 = loadList;
      newPostList1 = loadList.sort((a, b) => a.drafttime < b.drafttime);
      this.setState({
        // isoldestfirst: !this.state.isoldestfirst,
        loadList: newPostList1,
        sortstatus: "desc",
      });
    }
    // newPostList1 = loadList.sort((a, b) => a.time > b.time)
    // }
  }

  dsc1() {
    const { loadList } = this.state;
    let newPostList2 = loadList;
    newPostList2 = loadList.sort(
      (a, b) => a.questionImportance < b.questionImportance
    );

    this.setState({
      // isoldestfirst: !this.state.isoldestfirst,
      loadList: newPostList2,
      sortstatus: "urgent",
    });
  }

  searchvalue = (event) => {
    let keyword = event.target.value;
    this.setState({ search: keyword });
  };

  searchSpace = (event) => {
    event.preventDefault();

    if (this.state.search != "") {
      let searchlist = this.state.loadList.filter(
        (x) =>
          (x.reply_name || "")
            .toString()
            .toLowerCase()
            .includes((this.state.search || "").toLowerCase().trim()) ||
          x.questionTitle
            .toLowerCase()
            .includes(this.state.search.toLowerCase())
      );
      // x.reply_name == this.state.search || x.questionTitle == this.state.search);
      this.setState({
        loadList: searchlist,
        box: "search",
      });
    } else {
      this.setState({
        loadList: this.state.inboxList,
        search: "",
        box: "inbox",
        mailContent: "",
        selected: "",
      });
    }
  };

  audio() {
    const audioTune = new Audio(soundfile);

    audioTune.play();
  }

  render() {
    const mystyle = {
      color: "black",
      backgroundColor: "info",
      float: "center",
      marginTop: "1px",
      fontFamily: "digital7",
      fontSize: "2.0rem",
      fontWeight: "700",
      textShadow: "4px 4px 8px #000000",
    };

    //   var values = [];

    //   this.state.mailLists.forEach(item => {
    //       values.push(item.read=0,item.answer=0,item.option='',item.reply=0,item.cc=0)
    //    })

    var today = new Date(),
      time = today.getHours() + ":" + today.getMinutes();

    var { mailLists } = this.state;

    var { sendList } = this.state;

    var { draftList } = this.state;

    var { inboxList } = this.state;

    var { mailContent } = this.state;

    const space = this.state.inboxList.filter((x) => x.id == mailContent);

    // const arr = mailLists.filter(x => x.id === mailContent);

    const user = localStorage.getItem("uname1");

    const second = localStorage.getItem("uname3");

    //  function readstatus (id){

    //     this.state.loadLists.forEach(item => {
    //         if(item.id == id){
    //             item.push(item.read = item.read + 1)
    //         }

    //         values.push(item.read='0',item.answer="0",item.option='')
    //      })

    //  }

    var { loadList } = this.state;

    // const responseList = this.state.subquestionList.filter(
    //   (x) => x.parents !== ""
    // );

    var { responseList } = this.state;

    var { superresponseList } = this.state;

    // const responseList = this.state || [];

    // var FlashMessage = require("./FlashMessage.jsx");

    // React.render(  , document.getElementById('flash')

    return (
      <div
        className="container1 " //pl-0 pr-0
        style={{ border: "1px", lineHeight: "1.3rem", fontSize: "1.0rem" }}
        style={{ backgroundColor: "#FFFFFF" }}
      >
        <Navbar
          className=" navbar-light mt-0"
          // style={{ backgroundColor: "#C0C0C0" }} //blue=#0173c7 ash=#bfc9ca
          // style={{ backgroundColor: "#b8e2f2" }} 
        >
          <img className="login-logo-small" src={logo} alt=""></img>
          <Nav className="mr-auto">
            <NavItem>
              {/* <Button className="btn btn-primary btn-group btn-sm ml-3 rounded-pill" target='_blank' onClick={()=>{this.props.history.push("/welcome")}} >
                Company
              </Button> */}
              <Button className="btn btn-primary btn-group btn-sm ml-3 rounded-pill" target='_blank' onClick={()=>{window.open("/welcome1", "_blank")}} >
                Company
              </Button>
            </NavItem>
            {/* <NavItem>
              <Button className="btn btn-primary btn-group btn-sm ml-3 rounded-pill " type="button" >
                My Mail
              </Button>
            </NavItem> */}
          </Nav>
          {/* <Nav> 
           <NavItem>    */}
          <Timer
            className="mr-10"
            // initialTime={623.5 * 60 * 48 + 5000}
            // initialTime={421 * 60 * 48 - 5000}
            initialTime={314 * 60 * 48 - 5000}
            //  initialTime={23 * 60 * 48 - 5000 }
            // initialTime={9.2 * 60 * 48 - 5000} //20 seconds
            // initialTime={5.5 * 60 * 48 - 5000} //10 seconds
            direction="backward"
            checkpoints={[
              // {
              //     time: 417 * 60 * 48,
              //     callback: () => this.getdata1(),
              // },
              {
                time: 104.5 * 60 * 48,
                callback: () => alert("Last Five Minutes Left"),
              },
              {
                time: 22.8 * 60 * 48 - 5000,
                callback: () => alert("Last One Minute Left"),
              },
              {
                time: 5.5 * 60 * 48 - 5000,
                callback: () => this.audio(),

                // alert("Last 10 Seconds Left"),
              },
              {
                time: 2.1 * 60 * 48 - 5000,

                callback: () => {
                  this.setState({ show: true });
                },
              },
              // }
            ]}
          >
            {() => (
              <React.Fragment>
                {/* <h6 style={mystyle}> */}

                <span className="mr-5" style={mystyle}>
                  <Timer.Minutes /> m: <Timer.Seconds /> s{" "}
                </span>
                {/* </h6> */}
              </React.Fragment>
            )}
          </Timer>
          {/* </NavItem>
                 </Nav> */}
          <Nav>
            <BootBox
              message="The test has finished, Thank you for using E-Volve"
              show={this.state.show}
              // type={"alert"}
              onYesClick={this.showAlert}
              // onNoClick = {this.handleClosenew}
              onClose={this.handleClosenew}
            />

            {/* <Bootbox show={showAlert} 
                        type={"alert"}  
                        message={"This is a simple alert"}  
                        onClose={handleClose} 
                />           */}
            <NavItem>
              <Button className="btn btn-primary btn-sm mr-3 ml-5 rounded-pill" type="button" onClick={()=>{this.setState({show1:true})}}>
                Logout
              </Button>
            </NavItem>
          </Nav>
          <Nav>
            <NavItem>
              <p
                style={{
                  fontWeight: "700",
                  fontSize: "1.7rem",
                  color: "white",
                  textShadow: "4px 4px 8px #000000"
                }}
              >
                E-Volve
              </p>
            </NavItem>
          </Nav>
        </Navbar>

        <div>
        <Modal size="lg" show={this.state.show1} onHide={this.handleClosenew1} fade={false}>

<Modal.Header closeButton>

  <Modal.Title>Logout</Modal.Title>

</Modal.Header>

<Modal.Body>

You have opted to logout from the test. Are you sure you still want to logout?
              Kindly report to HR regarding the reason to logout.  Thank you for using E-Volve

</Modal.Body>

<Modal.Footer>

  <Button variant="secondary" onClick={this.showAlert1}>

    Yes

  </Button>

  <Button variant="primary" onClick={this.handleClosenew1}>

    No

  </Button>

</Modal.Footer>

</Modal>
        </div>

        <div className="content-wrapper">
          <div className="email-wrapper wrapper">
            <div className="row align-items-stretch">
              <div className="mail-sidebar d-none d-lg-block col-md-2 offset-md-0 pt-3 bg-white mt-1 float-left">
                <div className="menu-bar">
                  <ul className="menu-items">
                    <li className="compose mb-3" style={{ fontSize: "1.0rem" }}>
                      My Mail
                    </li>
                    <li
                      className={this.state.box == "inbox" ? "active" : ""}
                      onClick={this.inboxload.bind(this)}
                    >
                      <a href="#">
                        <i className="mdi mdi-email-outline"></i> Inbox
                      </a>
                      <span className="badge badge-pill badge-success">
                        {inboxList.length - this.state.read}
                      </span>
                    </li>
                    <li
                      className={this.state.box == "sent" ? "active" : ""}
                      onClick={this.sendload.bind(this)}
                      // disabled={sendList.length == 0}
                    >
                      <a href="#">
                        <i className="mdi mdi-share"></i> Sent
                      </a>
                      <span className="badge badge-pill badge-warning">
                        {sendList.length}
                      </span>
                    </li>
                    <li
                      className={this.state.box == "draft" ? "active" : ""}
                      onClick={this.draftload.bind(this)}
                    >
                      <a href="#">
                        <i className="mdi mdi-file-outline"></i> Draft
                      </a>
                      <span className="badge badge-pill badge-danger">
                        {draftList.length}
                      </span>
                    </li>
                  </ul>
                  {this.state.flash != "" && (
                    <FlashMessage duration={60000}>
                      <p className="p-3 mb-2 bg-danger text-white">
                        {this.state.flash}
                      </p>
                    </FlashMessage>
                  )}
                </div>
                {/* <FlashMessage
            messageHeader = ""
            messageBody = "New mails Arrived"
            type =  "Warning"
            autoDismiss = {true}
            timeout = {60000}
            hidden = {false}
          /> */}
                {/* <FlashMessage duration={5000}>
     <strong>I will disapper in 5 seconds!</strong>
     </FlashMessage> */}
              </div>

              <div className="mail-list-container col-md-3 pt-4 pb-4 border-right bg-white ">
                <div className="border-bottom pb-2 mb-1 px-3">
                  {/* <div className="form-group">
                                         <input className="form-control w-100" type="text" placeholder="Search mail" id="keyword" onChange={(e)=>this.searchSpace(e)}/><span><i class="fa fa-search"></i></span>
                                         </div> */}

                  <form
                    form="search"
                    id="search"
                    onSubmit={this.searchSpace.bind(this)}
                  >
                    <div className="p-1 bg-light rounded rounded-pill shadow-sm mb-4">
                      <div className="input-group">
                        <input
                          type="search"
                          value={this.state.search}
                          placeholder="Search Mail by Sender or Subject"
                          aria-describedby="button-addon1"
                          onChange={(e) => this.searchvalue(e)}
                          className="form-control border-0 bg-light"
                        />
                        <div className="input-group-append">
                          <button
                            form="search"
                            id="button-addon1"
                            type="submit"
                            className="btn btn-link text-primary"
                          >
                            <i className="fa fa-search"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="row">
                  <p className="content  ml-4">
                    Sort by:&nbsp;
                    <a
                    href="#"
                      className={
                        this.state.sortstatus == "urgent"
                          ? "bg-primary text-white"
                          : ""
                      }
                      href="#"
                      onClick={this.toggleListReverse}
                    >
                      Urgent
                    </a>
                    &nbsp;&nbsp;
                    <a
                      href="#"
                      className={
                        this.state.sortstatus == "asc"
                          ? "bg-primary text-white"
                          : ""
                      }
                      onClick={this.toggleSortDateAsc}
                    >
                      ASC
                    </a>
                    &nbsp;&nbsp;
                    <a
                      href=""
                      className={
                        this.state.sortstatus == "desc"
                          ? "bg-primary text-white"
                          : ""
                      }
                      onClick={this.toggleSortDateDsc}
                    >
                      DSC
                    </a>
                  </p>
                  {/* <button type="button" className="btn btn-sm mr-3 dropdown-item" onClick={this.toggleSortDateAsc}>
                                     ASC
                                    </button>
                                    <button type="button" className="btn btn-sm mr-3 dropdown-item" onClick={this.toggleSortDateDsc}>
                                     DSC
                                    </button> */}
                </div>

                <div>
                  {loadList.map((item, i) =>
                    this.state.box == "inbox" ? (
                      <div
                        key={i}
                        className="mail-list"
                        style={{
                          fontWeight: item.read === 0 ? "normal" : "bold",
                          backgroundColor:
                            this.state.selected == item.id ? "#bfc1c3" : "",
                        }}
                        id={item.id}
                        onClick={this.updateContent.bind(this, item)}
                      >
                        <div className="form-check ml-0">
                          {item.questionImportance === 1 && (
                            <p
                              style={{
                                color: "red",
                                fontSize: "1.5rem",
                                fontWeight: "700",
                              }}
                            >
                              !
                            </p>
                          )}
                        </div>
                        <div className="content">
                          <p className="sender-name">{item.reply_name}</p>{" "}
                          <p className="float-right pr-3">{item.time}</p>
                          {item.replymail == 1 && (
                            <img
                              className="img-xs rounded-circle float-right mr-3"
                              src={reply}
                              alt=""
                            />
                          )}{" "}
                          {item.ccmail == 1 && (
                            <img
                              className="img-xs rounded-circle float-right mr-3"
                              src={cc}
                              alt=""
                            />
                          )}
                          {item.draft == 0 && (
                            <p
                              className="float-right pr-3"
                              style={{
                                color: "red",
                                fontSize: "0.95rem",
                                fontWeight: "500",
                              }}
                            >
                              [Draft]
                            </p>
                          )}
                          {item.parentflag == 1 && (
                            <p
                              className="float-left pr-1"
                              style={{
                                color: "red",
                                fontSize: "1.05rem",
                                fontWeight: "700",
                              }}
                            >
                              Re:
                            </p>
                          )}
                          {item.superparentflag == 1 && (
                            <p
                              className="float-left pr-1"
                              style={{
                                color: "red",
                                fontSize: "1.05rem",
                                fontWeight: "700",
                              }}
                            >
                              Re:Re:
                            </p>
                          )}
                          <p className="subject">{item.questionTitle}</p>
                          <p className="message_text">
                            Dear {user},{item.question}
                          </p>
                        </div>

                        <div className="details">
                          <i className="mdi mdi-star-outline"></i>
                        </div>
                      </div>
                    ) : this.state.box == "send" ? (
                      <div
                        key={i}
                        className="mail-list"
                        style={{
                          fontWeight: item.read === 0 ? "normal" : "bold",
                          backgroundColor:
                            this.state.selected == item.id ? "#bfc1c3" : "",
                        }}
                        id={item.id}
                        onClick={this.updateContent.bind(this, item)}
                      >
                        <div className="form-check ml-0">
                          {item.questionImportance === 1 && (
                            <p
                              style={{
                                color: "red",
                                fontSize: "1.5rem",
                                fontWeight: "700",
                              }}
                            >
                              !
                            </p>
                          )}
                        </div>
                        <div className="content">
                          <p className="sender-name">{item.reply_name}</p>{" "}
                          <p className="float-right pr-3">{item.sendtime}</p>
                          <p className="subject">{item.questionTitle}</p>
                          <p className="message_text">
                            Dear {user},{item.question}
                          </p>
                        </div>

                        <div className="details">
                          <i className="mdi mdi-star-outline"></i>
                        </div>
                      </div>
                    ) : this.state.box == "draft" ? (
                      <div
                        key={i}
                        className="mail-list"
                        style={{
                          fontWeight: item.read === 0 ? "normal" : "bold",
                          backgroundColor:
                            this.state.selected == item.id ? "#bfc1c3" : "",
                        }}
                        id={item.id}
                        onClick={this.updateContent.bind(this, item)}
                      >
                        <div className="form-check ml-0">
                          {item.questionImportance === 1 && (
                            <p
                              style={{
                                color: "red",
                                fontSize: "1.5rem",
                                fontWeight: "700",
                              }}
                            >
                              !
                            </p>
                          )}
                        </div>
                        <div className="content">
                          <p className="sender-name">{item.reply_name}</p>{" "}
                          <p className="float-right pr-3">{item.drafttime}</p>
                          <p className="subject">{item.questionTitle}</p>
                          <p className="message_text">
                            Dear {user},{item.question}
                          </p>
                        </div>

                        <div className="details">
                          <i className="mdi mdi-star-outline"></i>
                        </div>
                      </div>
                    ) : (
                      <div
                        key={i}
                        className="mail-list"
                        style={{
                          fontWeight: item.read === 0 ? "normal" : "bold",
                          backgroundColor:
                            this.state.selected == item.id ? "#bfc1c3" : "",
                        }}
                        id={item.id}
                        onClick={this.updateContent.bind(this, item)}
                      >
                        <div className="form-check ml-0">
                          {item.questionImportance === 1 && (
                            <p
                              style={{
                                color: "red",
                                fontSize: "1.5rem",
                                fontWeight: "700",
                              }}
                            >
                              !
                            </p>
                          )}
                        </div>
                        <div className="content">
                          <p className="sender-name">{item.reply_name}</p>{" "}
                          <p className="float-right pr-3">{item.time}</p>
                          {item.replymail == 1 && (
                            <img
                              className="img-xs rounded-circle float-right mr-3"
                              src={reply}
                              alt=""
                            />
                          )}{" "}
                          {item.ccmail == 1 && (
                            <img
                              className="img-xs rounded-circle float-right mr-3"
                              src={cc}
                              alt=""
                            />
                          )}
                          {item.draft == 0 && (
                            <p
                              className="float-right pr-3"
                              style={{
                                color: "red",
                                fontSize: "0.95rem",
                                fontWeight: "500",
                              }}
                            >
                              [Draft]
                            </p>
                          )}
                          <p className="subject">{item.questionTitle}</p>
                          <p className="message_text">
                            Dear {user},{item.question}
                          </p>
                        </div>

                        <div className="details">
                          <i className="mdi mdi-star-outline"></i>
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>

              {space.map((items) => (
                <div
                  className="mail-view d-none d-md-block col-md-7 bg-white"
                  style={{ overflow: "scroll", height: "42vmax" }}
                  key={items.id}
                >
                  <div
                    className="row float-right mb-5 mr-1"
                    style={{ alignItems: "right" }}
                    disabled={!this.state.press}
                  >
                    <div className="col-md-12 mb-4 mt-2">
                      <div className="btn-toolbar">
                        <div
                          className="btn-group"
                          style={{
                            display:
                              items.answer === 0 || items.press == "1"
                                ? "none"
                                : "block",
                          }}
                          disabled={!this.state.press == "true"}
                        >
                          <button
                            type="button"
                            className="btn btn-sm btn-outline-secondary float-right"
                            style={{
                              display: items.answer === 0 ? "none" : "block",
                            }}
                            onClick={() => this.showcc()}
                            style={{ fontSize: "1.10rem", fontWeight: "700" }}
                          >
                            <i className="mdi mdi-reply-all text-primary mr-1"></i>
                            Reply All
                          </button>
                          <button
                            type="button"
                            className="btn btn-sm btn-outline-secondary float-right"
                            style={{
                              display: items.answer === 0 ? "none" : "block",
                            }}
                            onClick={() => this.showreply()}
                            style={{ fontSize: "1.10rem", fontWeight: "700" }}
                          >
                            <i className="mdi mdi-reply text-primary mr-1"></i>{" "}
                            Reply
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    {this.state.showreply ? (
                      <div className="message-head">
                        <Container
                          className="themed-container mt-5 borderradius-1"
                          fluid="md"
                          style={{ border: "1px", lineHeight: "1.2rem" }}
                        >
                          <button
                            hidden={items.answer === 0}
                            type="button"
                            onClick={() => this.discard()}
                          >
                            X discard
                          </button>
                          <br />
                          <br />
                          {this.state.box == "sent" ? (
                            <Row>
                              <Col sm={12}>
                                <InputGroup size="sm" horizontal>
                                  <InputGroupAddon addonType="prepend">
                                    To
                                  </InputGroupAddon>
                                  <Input readOnly value={items.reply_mail} />
                                </InputGroup>
                                <br />
                                <InputGroup size="sm">
                                  <InputGroupAddon addonType="prepend">
                                    Subject
                                  </InputGroupAddon>
                                  <Input readOnly value={items.questionTitle} />
                                </InputGroup>
                              </Col>
                            </Row>
                          ) : (
                            <Row>
                              <Col sm={2}>
                                <ButtonGroup vertical>
                                  <Button
                                    form="myform"
                                    type="submit"
                                    hidden={items.answer === 0}
                                    disabled={items.option == ""}
                                  >
                                    Send
                                  </Button>
                                </ButtonGroup>
                              </Col>
                              <Col sm={10}>
                                <InputGroup size="sm" horizontal>
                                  <InputGroupAddon addonType="prepend">
                                    To
                                  </InputGroupAddon>
                                  <Input readOnly value={items.reply_mail} />
                                </InputGroup>
                                <br />
                                <InputGroup size="sm">
                                  <InputGroupAddon addonType="prepend">
                                    Subject
                                  </InputGroupAddon>
                                  <Input readOnly value={items.questionTitle} />
                                </InputGroup>
                              </Col>
                            </Row>
                          )}
                          <br />

                          <Form
                            id="myform"
                            onSubmit={this.handleOnSubmit.bind(this)}
                          >
                            <FormGroup tag="fieldset" row>
                              <legend className="col-form-label col-sm-4">
                                You Will
                              </legend>

                              <Col sm={12}>
                                <FormGroup check>
                                  <Label check>
                                    <Input
                                      disabled={this.state.box == "sent"}
                                      type="radio"
                                      name="radio"
                                      value="optionA"
                                      checked={this.state.option === "optionA"}
                                      onChange={this.handleChange}
                                    />{" "}
                                    <span
                                      style={{
                                        fontWeight:
                                          this.state.option === "optionA"
                                            ? "700"
                                            : "",
                                      }}
                                    >
                                      {items.optionA}
                                    </span>
                                  </Label>
                                </FormGroup>
                                <br />
                                <FormGroup check>
                                  <Label check>
                                    <Input
                                      disabled={this.state.box == "sent"}
                                      type="radio"
                                      name="radio"
                                      value="optionB"
                                      checked={this.state.option === "optionB"}
                                      onChange={this.handleChange}
                                    />{" "}
                                    <span
                                      style={{
                                        fontWeight:
                                          this.state.option === "optionB"
                                            ? "700"
                                            : "",
                                      }}
                                    >
                                      {items.optionB}
                                    </span>
                                  </Label>
                                </FormGroup>
                                <br />
                                <FormGroup check>
                                  <Label check>
                                    <Input
                                      disabled={this.state.box == "sent"}
                                      type="radio"
                                      name="radio"
                                      value="optionC"
                                      checked={this.state.option === "optionC"}
                                      onChange={this.handleChange}
                                    />{" "}
                                    <span
                                      style={{
                                        fontWeight:
                                          this.state.option === "optionC"
                                            ? "700"
                                            : "",
                                      }}
                                    >
                                      {items.optionC}
                                    </span>
                                  </Label>
                                </FormGroup>
                                <br />
                                <FormGroup check>
                                  <Label check>
                                    <Input
                                      disabled={this.state.box == "sent"}
                                      type="radio"
                                      name="radio"
                                      value="optionD"
                                      checked={this.state.option === "optionD"}
                                      onChange={this.handleChange}
                                    />{" "}
                                    <span
                                      style={{
                                        fontWeight:
                                          this.state.option === "optionD"
                                            ? "700"
                                            : "",
                                      }}
                                    >
                                      {" "}
                                      {items.optionD}{" "}
                                    </span>
                                  </Label>
                                </FormGroup>
                                <br />
                                <p>Regards,</p>
                                <p>
                                  {localStorage.getItem("uname1")}{" "}
                                  {localStorage.getItem("uname3")}
                                </p>
                              </Col>
                            </FormGroup>
                          </Form>
                        </Container>
                      </div>
                    ) : null}
                  </div>

                  <div>
                    {this.state.showcc ? (
                      <div classname="message-head">
                        <Container
                          className="themed-container mt-5 mr-2"
                          fluid="sm"
                        >
                          <button
                            hidden={items.answer === 0}
                            type="button"
                            onClick={() => this.discardcc()}
                          >
                            X discard
                          </button>
                          <br />
                          <br />
                          {this.state.box == "sent" ? (
                            <Row>
                              <Col sm={12} classname="float-left">
                                <InputGroup size="sm">
                                  <InputGroupAddon addonType="prepend">
                                    To
                                  </InputGroupAddon>
                                  <Input readOnly value={items.reply_mail} />
                                </InputGroup>
                                <br />
                                <InputGroup size="sm">
                                  <InputGroupAddon addonType="prepend">
                                    Cc
                                  </InputGroupAddon>
                                  <Input readOnly value={items.cc_mail} />
                                </InputGroup>
                                <br />
                                <InputGroup size="sm">
                                  <InputGroupAddon addonType="prepend">
                                    Subject
                                  </InputGroupAddon>
                                  <Input readOnly value={items.questionTitle} />
                                </InputGroup>
                              </Col>
                            </Row>
                          ) : (
                            <Row>
                              <Col sm={2}>
                                <ButtonGroup vertical>
                                  <Button
                                    hidden={items.answer === 0}
                                    form="myform"
                                    type="submit"
                                    disabled={items.option == ""}
                                  >
                                    Send
                                  </Button>
                                </ButtonGroup>
                              </Col>
                              <Col
                                sm={10}
                                classname="float-left"
                                style={{ alignContent: "left" }}
                              >
                                <InputGroup size="sm">
                                  <InputGroupAddon addonType="prepend">
                                    To
                                  </InputGroupAddon>
                                  <Input readOnly value={items.reply_mail} />
                                </InputGroup>
                                <br />
                                <InputGroup size="sm">
                                  <InputGroupAddon addonType="prepend">
                                    Cc
                                  </InputGroupAddon>
                                  <Input readOnly value={items.cc_mail} />
                                </InputGroup>
                                <br />
                                <InputGroup size="sm">
                                  <InputGroupAddon addonType="prepend">
                                    Subject
                                  </InputGroupAddon>
                                  <Input readOnly value={items.questionTitle} />
                                </InputGroup>
                              </Col>
                            </Row>
                          )}

                          <br />
                          <Form
                            id="myform"
                            onSubmit={this.handleOnSubmit.bind(this)}
                          >
                            <FormGroup tag="fieldset" row>
                              <legend className="col-form-label col-sm-4">
                                You Will
                              </legend>

                              <Col sm={12}>
                                <FormGroup
                                  check
                                  required
                                  feedback="Select an option before submitting."
                                >
                                  <Label check>
                                    <Input
                                      disabled={this.state.box == "sent"}
                                      type="radio"
                                      name="radio"
                                      value="optionA"
                                      checked={this.state.option === "optionA"}
                                      onChange={this.handleChange}
                                    />{" "}
                                    <span
                                      style={{
                                        fontWeight:
                                          this.state.option === "optionA"
                                            ? "700"
                                            : "",
                                      }}
                                    >
                                      {items.optionA}
                                    </span>
                                  </Label>
                                </FormGroup>
                                <br />
                                <FormGroup check>
                                  <Label check>
                                    <Input
                                      disabled={this.state.box == "sent"}
                                      type="radio"
                                      name="radio"
                                      value="optionB"
                                      checked={this.state.option === "optionB"}
                                      onChange={this.handleChange}
                                    />{" "}
                                    <span
                                      style={{
                                        fontWeight:
                                          this.state.option === "optionB"
                                            ? "700"
                                            : "",
                                      }}
                                    >
                                      {items.optionB}
                                    </span>
                                  </Label>
                                </FormGroup>
                                <br />
                                <FormGroup check>
                                  <Label check>
                                    <Input
                                      disabled={this.state.box == "sent"}
                                      type="radio"
                                      name="radio"
                                      value="optionC"
                                      checked={this.state.option === "optionC"}
                                      onChange={this.handleChange}
                                    />{" "}
                                    <span
                                      style={{
                                        fontWeight:
                                          this.state.option === "optionC"
                                            ? "700"
                                            : "",
                                      }}
                                    >
                                      {items.optionC}
                                    </span>
                                  </Label>
                                </FormGroup>
                                <br />
                                <FormGroup check>
                                  <Label check>
                                    <Input
                                      disabled={this.state.box == "sent"}
                                      type="radio"
                                      name="radio"
                                      value="optionD"
                                      checked={this.state.option === "optionD"}
                                      onChange={this.handleChange}
                                    />{" "}
                                    <span
                                      style={{
                                        fontWeight:
                                          this.state.option === "optionD"
                                            ? "700"
                                            : "",
                                      }}
                                    >
                                      {items.optionD}
                                    </span>
                                  </Label>
                                </FormGroup>
                                <br />
                                <p>Regards,</p>
                                <p>
                                  {localStorage.getItem("uname1")}{" "}
                                  {localStorage.getItem("uname3")}
                                </p>
                              </Col>
                            </FormGroup>
                          </Form>
                        </Container>
                      </div>
                    ) : null}
                  </div>

                  <div className="message-body mt-5">
                    <div className="sender-details">
                      <img
                        className="img-xs rounded-circle mr-3"
                        src={avatar}
                        alt=""
                      />
                      <div className="details">
                        <p className="msg-subject">
                          Subject: {items.questionTitle}
                        </p>
                        <p className="sender-email">
                          From: {items.reply_name} &nbsp;
                          <a>{items.reply_mail}</a>
                        </p>
                        <p className="receiver-email">
                          To: {user} {second} &nbsp;
                          <a>{localStorage.getItem("mail")}</a>
                        </p>

                        {items.cc_mail !== "" && (
                          <p className="receiver-email">
                            Cc: &nbsp;
                            <a>{items.cc_mail}</a>
                          </p>
                        )}

                        {items.sent == 0 && (
                          <a href="#" onClick={this.sentShow.bind(this, items)}>
                            {" "}
                            <p
                              className="receiver-email"
                              style={{
                                color: "red",
                                fontSize: "1.0rem",
                                fontWeight: "700",
                              }}
                            >
                              This mail has been replied
                            </p>
                          </a>
                        )}

                        {items.draft == 0 && (
                          <a>
                            {" "}
                            <p
                              className="receiver-email"
                              style={{
                                color: "red",
                                fontSize: "1.0rem",
                                fontWeight: "700",
                              }}
                            >
                              This mail is in draft stage
                            </p>
                          </a>
                        )}
                      </div>
                    </div>

                    <div className="message-content">
                      {/* <p>{salutation}</p>  */}
                      <p>Dear {user},</p>
                      <p><pre>{items.question}</pre></p>
                      <p>
                        <br />
                        Regards,
                        <br />
                        {items.reply_name}
                      </p>
                    </div>
                  </div>

                  {items.superparentflag == 1 && (
                    <div>
                      {superresponseList.map((itemval) => (
                        <div>
                          <div>
                            {itemval.replymail == 1 ? (
                              <div className="message-head">
                                <Container
                                  className="themed-container mt-5 borderradius-1"
                                  fluid="md"
                                  style={{
                                    border: "1px",
                                    lineHeight: "1.2rem",
                                  }}
                                >
                                  <Row>
                                    <Col sm={12}>
                                      <InputGroup size="sm" horizontal>
                                        <InputGroupAddon addonType="prepend">
                                          To
                                        </InputGroupAddon>
                                        <Input
                                          readOnly
                                          value={itemval.reply_mail}
                                        />
                                      </InputGroup>
                                      <br />
                                      <InputGroup size="sm">
                                        <InputGroupAddon addonType="prepend">
                                          Subject
                                        </InputGroupAddon>
                                        <Input
                                          readOnly
                                          value={itemval.questionTitle}
                                        />
                                      </InputGroup>
                                    </Col>
                                  </Row>

                                  <br />

                                  <Form
                                    id="myform"
                                    onSubmit={this.handleOnSubmit.bind(this)}
                                  >
                                    <FormGroup tag="fieldset" row>
                                      <legend className="col-form-label col-sm-4">
                                        You Will
                                      </legend>

                                      <Col sm={12}>
                                        <FormGroup check>
                                          <Label check>
                                            <Input
                                              disabled="disabled"
                                              type="radio"
                                              name="radio"
                                              value="optionA"
                                              checked={
                                                itemval.option === "optionA"
                                              }
                                              onChange={this.handleChange}
                                            />{" "}
                                            <span
                                              style={{
                                                fontWeight:
                                                  itemval.option === "optionA"
                                                    ? "700"
                                                    : "",
                                              }}
                                            >
                                              {itemval.optionA}
                                            </span>
                                          </Label>
                                        </FormGroup>
                                        <br />
                                        <FormGroup check>
                                          <Label check>
                                            <Input
                                              disabled="disabled"
                                              type="radio"
                                              name="radio"
                                              value="optionB"
                                              checked={
                                                itemval.option === "optionB"
                                              }
                                              onChange={this.handleChange}
                                            />{" "}
                                            <span
                                              style={{
                                                fontWeight:
                                                  itemval.option === "optionB"
                                                    ? "700"
                                                    : "",
                                              }}
                                            >
                                              {itemval.optionB}
                                            </span>
                                          </Label>
                                        </FormGroup>
                                        <br />
                                        <FormGroup check>
                                          <Label check>
                                            <Input
                                              disabled="disabled"
                                              type="radio"
                                              name="radio"
                                              value="optionC"
                                              checked={
                                                itemval.option === "optionC"
                                              }
                                              onChange={this.handleChange}
                                            />{" "}
                                            <span
                                              style={{
                                                fontWeight:
                                                  itemval.option === "optionC"
                                                    ? "700"
                                                    : "",
                                              }}
                                            >
                                              {itemval.optionC}
                                            </span>
                                          </Label>
                                        </FormGroup>
                                        <br />
                                        <FormGroup check>
                                          <Label check>
                                            <Input
                                              disabled="disabled"
                                              type="radio"
                                              name="radio"
                                              value="optionD"
                                              checked={
                                                itemval.option === "optionD"
                                              }
                                              onChange={this.handleChange}
                                            />{" "}
                                            <span
                                              style={{
                                                fontWeight:
                                                  itemval.option === "optionD"
                                                    ? "700"
                                                    : "",
                                              }}
                                            >
                                              {" "}
                                              {itemval.optionD}{" "}
                                            </span>
                                          </Label>
                                        </FormGroup>
                                        <br />
                                        <p>Regards,</p>
                                        <p>
                                          {localStorage.getItem("uname1")}{" "}
                                          {localStorage.getItem("uname3")}
                                        </p>
                                      </Col>
                                    </FormGroup>
                                  </Form>
                                </Container>
                              </div>
                            ) : null}
                          </div>

                          <div>
                            {itemval.ccmail == 1 ? (
                              <div classname="message-head">
                                <Container
                                  className="themed-container mt-5 mr-2"
                                  fluid="sm"
                                >
                                  <Row>
                                    <Col sm={12} classname="float-left">
                                      <InputGroup size="sm">
                                        <InputGroupAddon addonType="prepend">
                                          To
                                        </InputGroupAddon>
                                        <Input
                                          readOnly
                                          value={itemval.reply_mail}
                                        />
                                      </InputGroup>
                                      <br />
                                      <InputGroup size="sm">
                                        <InputGroupAddon addonType="prepend">
                                          Cc
                                        </InputGroupAddon>
                                        <Input
                                          readOnly
                                          value={itemval.cc_mail}
                                        />
                                      </InputGroup>
                                      <br />
                                      <InputGroup size="sm">
                                        <InputGroupAddon addonType="prepend">
                                          Subject
                                        </InputGroupAddon>
                                        <Input
                                          readOnly
                                          value={itemval.questionTitle}
                                        />
                                      </InputGroup>
                                    </Col>
                                  </Row>

                                  <br />
                                  <Form
                                    id="myform"
                                    onSubmit={this.handleOnSubmit.bind(this)}
                                  >
                                    <FormGroup tag="fieldset" row>
                                      <legend className="col-form-label col-sm-4">
                                        You Will
                                      </legend>

                                      <Col sm={12}>
                                        <FormGroup
                                          check
                                          required
                                          feedback="Select an option before submitting."
                                        >
                                          <Label check>
                                            <Input
                                              disabled="disabled"
                                              type="radio"
                                              name="radio"
                                              value="optionA"
                                              checked={
                                                itemval.option === "optionA"
                                              }
                                              onChange={this.handleChange}
                                            />{" "}
                                            <span
                                              style={{
                                                fontWeight:
                                                  itemval.option === "optionA"
                                                    ? "700"
                                                    : "",
                                              }}
                                            >
                                              {itemval.optionA}
                                            </span>
                                          </Label>
                                        </FormGroup>
                                        <br />
                                        <FormGroup check>
                                          <Label check>
                                            <Input
                                              disabled="disabled"
                                              type="radio"
                                              name="radio"
                                              value="optionB"
                                              checked={
                                                itemval.option === "optionB"
                                              }
                                              onChange={this.handleChange}
                                            />{" "}
                                            <span
                                              style={{
                                                fontWeight:
                                                  itemval.option === "optionB"
                                                    ? "700"
                                                    : "",
                                              }}
                                            >
                                              {itemval.optionB}
                                            </span>
                                          </Label>
                                        </FormGroup>
                                        <br />
                                        <FormGroup check>
                                          <Label check>
                                            <Input
                                              disabled="disabled"
                                              type="radio"
                                              name="radio"
                                              value="optionC"
                                              checked={
                                                itemval.option === "optionC"
                                              }
                                              onChange={this.handleChange}
                                            />{" "}
                                            <span
                                              style={{
                                                fontWeight:
                                                  itemval.option === "optionC"
                                                    ? "700"
                                                    : "",
                                              }}
                                            >
                                              {itemval.optionC}
                                            </span>
                                          </Label>
                                        </FormGroup>
                                        <br />
                                        <FormGroup check>
                                          <Label check>
                                            <Input
                                              disabled="disabled"
                                              type="radio"
                                              name="radio"
                                              value="optionD"
                                              checked={
                                                itemval.option === "optionD"
                                              }
                                              onChange={this.handleChange}
                                            />{" "}
                                            <span
                                              style={{
                                                fontWeight:
                                                  itemval.option === "optionD"
                                                    ? "700"
                                                    : "",
                                              }}
                                            >
                                              {itemval.optionD}
                                            </span>
                                          </Label>
                                        </FormGroup>
                                        <br />
                                        <p>Regards,</p>
                                        <p>
                                          {localStorage.getItem("uname1")}{" "}
                                          {localStorage.getItem("uname3")}
                                        </p>
                                      </Col>
                                    </FormGroup>
                                  </Form>
                                </Container>
                              </div>
                            ) : null}
                          </div>

                          <div className="message-body mt-5">
                            <div className="sender-details">
                              <img
                                className="img-xs rounded-circle mr-3"
                                src={avatar}
                                alt=""
                              />
                              <div className="details">
                                <p className="msg-subject">
                                  Subject: {itemval.questionTitle}
                                </p>
                                <p className="sender-email">
                                  From: {itemval.reply_name} &nbsp;
                                  <a>{itemval.reply_mail}</a>
                                </p>
                                <p className="receiver-email">
                                  To: {user} {second} &nbsp;
                                  <a>{localStorage.getItem("mail")}</a>
                                </p>

                                {itemval.cc_mail !== "" && (
                                  <p className="receiver-email">
                                    Cc: &nbsp;
                                    <a>{itemval.cc_mail}</a>
                                  </p>
                                )}

                                {itemval.sent == 0 && (
                                  <a
                                    href="#"
                                    onClick={this.sentShow.bind(this, items)}
                                  >
                                    {" "}
                                    <p
                                      className="receiver-email"
                                      style={{
                                        color: "red",
                                        fontSize: "1.0rem",
                                        fontWeight: "700",
                                      }}
                                    >
                                      This mail has been replied
                                    </p>
                                  </a>
                                )}

                                {itemval.draft == 0 && (
                                  <a>
                                    {" "}
                                    <p
                                      className="receiver-email"
                                      style={{
                                        color: "red",
                                        fontSize: "1.0rem",
                                        fontWeight: "700",
                                      }}
                                    >
                                      This mail is in draft stage
                                    </p>
                                  </a>
                                )}
                              </div>
                            </div>

                            <div className="message-content">
                              {/* <p>{salutation}</p>  */}
                              <p>Dear {user},</p>
                              <p>{itemval.question}</p>
                              <p>
                                <br />
                                Regards,
                                <br />
                                {itemval.reply_name}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                      <div>
                        {responseList.map((item) => (
                          <div>
                            {item.id == items.mquestion_id && (
                              <div>
                                <div>
                                  {item.replymail == 1 ? (
                                    <div className="message-head">
                                      <Container
                                        className="themed-container mt-5 borderradius-1"
                                        fluid="md"
                                        style={{
                                          border: "1px",
                                          lineHeight: "1.2rem",
                                        }}
                                      >
                                        <Row>
                                          <Col sm={12}>
                                            <InputGroup size="sm" horizontal>
                                              <InputGroupAddon addonType="prepend">
                                                To
                                              </InputGroupAddon>
                                              <Input
                                                readOnly
                                                value={item.reply_mail}
                                              />
                                            </InputGroup>
                                            <br />
                                            <InputGroup size="sm">
                                              <InputGroupAddon addonType="prepend">
                                                Subject
                                              </InputGroupAddon>
                                              <Input
                                                readOnly
                                                value={item.questionTitle}
                                              />
                                            </InputGroup>
                                          </Col>
                                        </Row>

                                        <br />

                                        <Form
                                          id="myform"
                                          onSubmit={this.handleOnSubmit.bind(
                                            this
                                          )}
                                        >
                                          <FormGroup tag="fieldset" row>
                                            <legend className="col-form-label col-sm-4">
                                              You Will
                                            </legend>

                                            <Col sm={12}>
                                              <FormGroup check>
                                                <Label check>
                                                  <Input
                                                    disabled="disabled"
                                                    type="radio"
                                                    name="radio"
                                                    value="optionA"
                                                    checked={
                                                      item.option === "optionA"
                                                    }
                                                    onChange={this.handleChange}
                                                  />{" "}
                                                  <span
                                                    style={{
                                                      fontWeight:
                                                        item.option ===
                                                        "optionA"
                                                          ? "700"
                                                          : "",
                                                    }}
                                                  >
                                                    {item.optionA}
                                                  </span>
                                                </Label>
                                              </FormGroup>
                                              <br />
                                              <FormGroup check>
                                                <Label check>
                                                  <Input
                                                    disabled="disabled"
                                                    type="radio"
                                                    name="radio"
                                                    value="optionB"
                                                    checked={
                                                      item.option === "optionB"
                                                    }
                                                    onChange={this.handleChange}
                                                  />{" "}
                                                  <span
                                                    style={{
                                                      fontWeight:
                                                        item.option ===
                                                        "optionB"
                                                          ? "700"
                                                          : "",
                                                    }}
                                                  >
                                                    {item.optionB}
                                                  </span>
                                                </Label>
                                              </FormGroup>
                                              <br />
                                              <FormGroup check>
                                                <Label check>
                                                  <Input
                                                    disabled="disabled"
                                                    type="radio"
                                                    name="radio"
                                                    value="optionC"
                                                    checked={
                                                      item.option === "optionC"
                                                    }
                                                    onChange={this.handleChange}
                                                  />{" "}
                                                  <span
                                                    style={{
                                                      fontWeight:
                                                        item.option ===
                                                        "optionC"
                                                          ? "700"
                                                          : "",
                                                    }}
                                                  >
                                                    {item.optionC}
                                                  </span>
                                                </Label>
                                              </FormGroup>
                                              <br />
                                              <FormGroup check>
                                                <Label check>
                                                  <Input
                                                    disabled="disabled"
                                                    type="radio"
                                                    name="radio"
                                                    value="optionD"
                                                    checked={
                                                      item.option === "optionD"
                                                    }
                                                    onChange={this.handleChange}
                                                  />{" "}
                                                  <span
                                                    style={{
                                                      fontWeight:
                                                        item.option ===
                                                        "optionD"
                                                          ? "700"
                                                          : "",
                                                    }}
                                                  >
                                                    {" "}
                                                    {item.optionD}{" "}
                                                  </span>
                                                </Label>
                                              </FormGroup>
                                              <br />
                                              <p>Regards,</p>
                                              <p>
                                                {localStorage.getItem("uname1")}{" "}
                                                {localStorage.getItem("uname3")}
                                              </p>
                                            </Col>
                                          </FormGroup>
                                        </Form>
                                      </Container>
                                    </div>
                                  ) : null}
                                </div>

                                <div>
                                  {item.ccmail == 1 ? (
                                    <div classname="message-head">
                                      <Container
                                        className="themed-container mt-5 mr-2"
                                        fluid="sm"
                                      >
                                        <Row>
                                          <Col sm={12} classname="float-left">
                                            <InputGroup size="sm">
                                              <InputGroupAddon addonType="prepend">
                                                To
                                              </InputGroupAddon>
                                              <Input
                                                readOnly
                                                value={item.reply_mail}
                                              />
                                            </InputGroup>
                                            <br />
                                            <InputGroup size="sm">
                                              <InputGroupAddon addonType="prepend">
                                                Cc
                                              </InputGroupAddon>
                                              <Input
                                                readOnly
                                                value={item.cc_mail}
                                              />
                                            </InputGroup>
                                            <br />
                                            <InputGroup size="sm">
                                              <InputGroupAddon addonType="prepend">
                                                Subject
                                              </InputGroupAddon>
                                              <Input
                                                readOnly
                                                value={item.questionTitle}
                                              />
                                            </InputGroup>
                                          </Col>
                                        </Row>

                                        <br />
                                        <Form
                                          id="myform"
                                          onSubmit={this.handleOnSubmit.bind(
                                            this
                                          )}
                                        >
                                          <FormGroup tag="fieldset" row>
                                            <legend className="col-form-label col-sm-4">
                                              You Will
                                            </legend>

                                            <Col sm={12}>
                                              <FormGroup
                                                check
                                                required
                                                feedback="Select an option before submitting."
                                              >
                                                <Label check>
                                                  <Input
                                                    disabled="disabled"
                                                    type="radio"
                                                    name="radio"
                                                    value="optionA"
                                                    checked={
                                                      item.option === "optionA"
                                                    }
                                                    onChange={this.handleChange}
                                                  />{" "}
                                                  <span
                                                    style={{
                                                      fontWeight:
                                                        item.option ===
                                                        "optionA"
                                                          ? "700"
                                                          : "",
                                                    }}
                                                  >
                                                    {item.optionA}
                                                  </span>
                                                </Label>
                                              </FormGroup>
                                              <br />
                                              <FormGroup check>
                                                <Label check>
                                                  <Input
                                                    disabled="disabled"
                                                    type="radio"
                                                    name="radio"
                                                    value="optionB"
                                                    checked={
                                                      item.option === "optionB"
                                                    }
                                                    onChange={this.handleChange}
                                                  />{" "}
                                                  <span
                                                    style={{
                                                      fontWeight:
                                                        item.option ===
                                                        "optionB"
                                                          ? "700"
                                                          : "",
                                                    }}
                                                  >
                                                    {item.optionB}
                                                  </span>
                                                </Label>
                                              </FormGroup>
                                              <br />
                                              <FormGroup check>
                                                <Label check>
                                                  <Input
                                                    disabled="disabled"
                                                    type="radio"
                                                    name="radio"
                                                    value="optionC"
                                                    checked={
                                                      item.option === "optionC"
                                                    }
                                                    onChange={this.handleChange}
                                                  />{" "}
                                                  <span
                                                    style={{
                                                      fontWeight:
                                                        item.option ===
                                                        "optionC"
                                                          ? "700"
                                                          : "",
                                                    }}
                                                  >
                                                    {item.optionC}
                                                  </span>
                                                </Label>
                                              </FormGroup>
                                              <br />
                                              <FormGroup check>
                                                <Label check>
                                                  <Input
                                                    disabled="disabled"
                                                    type="radio"
                                                    name="radio"
                                                    value="optionD"
                                                    checked={
                                                      item.option === "optionD"
                                                    }
                                                    onChange={this.handleChange}
                                                  />{" "}
                                                  <span
                                                    style={{
                                                      fontWeight:
                                                        item.option ===
                                                        "optionD"
                                                          ? "700"
                                                          : "",
                                                    }}
                                                  >
                                                    {item.optionD}
                                                  </span>
                                                </Label>
                                              </FormGroup>
                                              <br />
                                              <p>Regards,</p>
                                              <p>
                                                {localStorage.getItem("uname1")}{" "}
                                                {localStorage.getItem("uname3")}
                                              </p>
                                            </Col>
                                          </FormGroup>
                                        </Form>
                                      </Container>
                                    </div>
                                  ) : null}
                                </div>

                                <div className="message-body mt-5">
                                  <div className="sender-details">
                                    <img
                                      className="img-xs rounded-circle mr-3"
                                      src={avatar}
                                      alt=""
                                    />
                                    <div className="details">
                                      <p className="msg-subject">
                                        Subject: {item.questionTitle}
                                      </p>
                                      <p className="sender-email">
                                        From: {item.reply_name} &nbsp;
                                        <a>{item.reply_mail}</a>
                                      </p>
                                      <p className="receiver-email">
                                        To: {user} {second} &nbsp;
                                        <a>{localStorage.getItem("mail")}</a>
                                      </p>

                                      {item.cc_mail !== "" && (
                                        <p className="receiver-email">
                                          Cc: &nbsp;
                                          <a>{item.cc_mail}</a>
                                        </p>
                                      )}

                                      {item.sent == 0 && (
                                        <a
                                          href="#"
                                          onClick={this.sentShow.bind(
                                            this,
                                            items
                                          )}
                                        >
                                          {" "}
                                          <p
                                            className="receiver-email"
                                            style={{
                                              color: "red",
                                              fontSize: "1.0rem",
                                              fontWeight: "700",
                                            }}
                                          >
                                            This mail has been replied
                                          </p>
                                        </a>
                                      )}

                                      {item.draft == 0 && (
                                        <a>
                                          {" "}
                                          <p
                                            className="receiver-email"
                                            style={{
                                              color: "red",
                                              fontSize: "1.0rem",
                                              fontWeight: "700",
                                            }}
                                          >
                                            This mail is in draft stage
                                          </p>
                                        </a>
                                      )}
                                    </div>
                                  </div>

                                  <div className="message-content">
                                    {/* <p>{salutation}</p>  */}
                                    <p>Dear {user},</p>
                                    <p>{item.question}</p>
                                    <p>
                                      <br />
                                      Regards,
                                      <br />
                                      {item.reply_name}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  {items.parentflag == 1 && (
                    <div>
                      {responseList.map((itemparent) => (
                        // {item.id == items.id}
                        <div>
                          {itemparent.id == items.mquestion_id && (
                            <div>
                              <div>
                                {itemparent.replymail == 1 ? (
                                  <div className="message-head">
                                    <Container
                                      className="themed-container mt-5 borderradius-1"
                                      fluid="md"
                                      style={{
                                        border: "1px",
                                        lineHeight: "1.2rem",
                                      }}
                                    >
                                      <Row>
                                        <Col sm={12}>
                                          <InputGroup size="sm" horizontal>
                                            <InputGroupAddon addonType="prepend">
                                              To
                                            </InputGroupAddon>
                                            <Input
                                              readOnly
                                              value={itemparent.reply_mail}
                                            />
                                          </InputGroup>
                                          <br />
                                          <InputGroup size="sm">
                                            <InputGroupAddon addonType="prepend">
                                              Subject
                                            </InputGroupAddon>
                                            <Input
                                              readOnly
                                              value={itemparent.questionTitle}
                                            />
                                          </InputGroup>
                                        </Col>
                                      </Row>

                                      <br />

                                      <Form
                                        id="myform"
                                        onSubmit={this.handleOnSubmit.bind(
                                          this
                                        )}
                                      >
                                        <FormGroup tag="fieldset" row>
                                          <legend className="col-form-label col-sm-4">
                                            You Will
                                          </legend>

                                          <Col sm={12}>
                                            <FormGroup check>
                                              <Label check>
                                                <Input
                                                  disabled="disabled"
                                                  type="radio"
                                                  name="radio"
                                                  value="optionA"
                                                  checked={
                                                    itemparent.option ===
                                                    "optionA"
                                                  }
                                                  onChange={this.handleChange}
                                                />{" "}
                                                <span
                                                  style={{
                                                    fontWeight:
                                                      itemparent.option ===
                                                      "optionA"
                                                        ? "700"
                                                        : "",
                                                  }}
                                                >
                                                  {itemparent.optionA}
                                                </span>
                                              </Label>
                                            </FormGroup>
                                            <br />
                                            <FormGroup check>
                                              <Label check>
                                                <Input
                                                  disabled="disabled"
                                                  type="radio"
                                                  name="radio"
                                                  value="optionB"
                                                  checked={
                                                    itemparent.option ===
                                                    "optionB"
                                                  }
                                                  onChange={this.handleChange}
                                                />{" "}
                                                <span
                                                  style={{
                                                    fontWeight:
                                                      itemparent.option ===
                                                      "optionB"
                                                        ? "700"
                                                        : "",
                                                  }}
                                                >
                                                  {itemparent.optionB}
                                                </span>
                                              </Label>
                                            </FormGroup>
                                            <br />
                                            <FormGroup check>
                                              <Label check>
                                                <Input
                                                  disabled="disabled"
                                                  type="radio"
                                                  name="radio"
                                                  value="optionC"
                                                  checked={
                                                    itemparent.option ===
                                                    "optionC"
                                                  }
                                                  onChange={this.handleChange}
                                                />{" "}
                                                <span
                                                  style={{
                                                    fontWeight:
                                                      itemparent.option ===
                                                      "optionC"
                                                        ? "700"
                                                        : "",
                                                  }}
                                                >
                                                  {itemparent.optionC}
                                                </span>
                                              </Label>
                                            </FormGroup>
                                            <br />
                                            <FormGroup check>
                                              <Label check>
                                                <Input
                                                  disabled="disabled"
                                                  type="radio"
                                                  name="radio"
                                                  value="optionD"
                                                  checked={
                                                    itemparent.option ===
                                                    "optionD"
                                                  }
                                                  onChange={this.handleChange}
                                                />{" "}
                                                <span
                                                  style={{
                                                    fontWeight:
                                                      itemparent.option ===
                                                      "optionD"
                                                        ? "700"
                                                        : "",
                                                  }}
                                                >
                                                  {" "}
                                                  {itemparent.optionD}{" "}
                                                </span>
                                              </Label>
                                            </FormGroup>
                                            <br />
                                            <p>Regards,</p>
                                            <p>
                                              {localStorage.getItem("uname1")}{" "}
                                              {localStorage.getItem("uname3")}
                                            </p>
                                          </Col>
                                        </FormGroup>
                                      </Form>
                                    </Container>
                                  </div>
                                ) : null}
                              </div>

                              <div>
                                {itemparent.ccmail == 1 ? (
                                  <div classname="message-head">
                                    <Container
                                      className="themed-container mt-5 mr-2"
                                      fluid="sm"
                                    >
                                      <Row>
                                        <Col sm={12} classname="float-left">
                                          <InputGroup size="sm">
                                            <InputGroupAddon addonType="prepend">
                                              To
                                            </InputGroupAddon>
                                            <Input
                                              readOnly
                                              value={itemparent.reply_mail}
                                            />
                                          </InputGroup>
                                          <br />
                                          <InputGroup size="sm">
                                            <InputGroupAddon addonType="prepend">
                                              Cc
                                            </InputGroupAddon>
                                            <Input
                                              readOnly
                                              value={itemparent.cc_mail}
                                            />
                                          </InputGroup>
                                          <br />
                                          <InputGroup size="sm">
                                            <InputGroupAddon addonType="prepend">
                                              Subject
                                            </InputGroupAddon>
                                            <Input
                                              readOnly
                                              value={itemparent.questionTitle}
                                            />
                                          </InputGroup>
                                        </Col>
                                      </Row>

                                      <br />
                                      <Form
                                        id="myform"
                                        onSubmit={this.handleOnSubmit.bind(
                                          this
                                        )}
                                      >
                                        <FormGroup tag="fieldset" row>
                                          <legend className="col-form-label col-sm-4">
                                            You Will
                                          </legend>

                                          <Col sm={12}>
                                            <FormGroup
                                              check
                                              required
                                              feedback="Select an option before submitting."
                                            >
                                              <Label check>
                                                <Input
                                                  disabled="disabled"
                                                  type="radio"
                                                  name="radio"
                                                  value="optionA"
                                                  checked={
                                                    itemparent.option ===
                                                    "optionA"
                                                  }
                                                  onChange={this.handleChange}
                                                />{" "}
                                                <span
                                                  style={{
                                                    fontWeight:
                                                      itemparent.option ===
                                                      "optionA"
                                                        ? "700"
                                                        : "",
                                                  }}
                                                >
                                                  {itemparent.optionA}
                                                </span>
                                              </Label>
                                            </FormGroup>
                                            <br />
                                            <FormGroup check>
                                              <Label check>
                                                <Input
                                                  disabled="disabled"
                                                  type="radio"
                                                  name="radio"
                                                  value="optionB"
                                                  checked={
                                                    itemparent.option ===
                                                    "optionB"
                                                  }
                                                  onChange={this.handleChange}
                                                />{" "}
                                                <span
                                                  style={{
                                                    fontWeight:
                                                      itemparent.option ===
                                                      "optionB"
                                                        ? "700"
                                                        : "",
                                                  }}
                                                >
                                                  {itemparent.optionB}
                                                </span>
                                              </Label>
                                            </FormGroup>
                                            <br />
                                            <FormGroup check>
                                              <Label check>
                                                <Input
                                                  disabled="disabled"
                                                  type="radio"
                                                  name="radio"
                                                  value="optionC"
                                                  checked={
                                                    itemparent.option ===
                                                    "optionC"
                                                  }
                                                  onChange={this.handleChange}
                                                />{" "}
                                                <span
                                                  style={{
                                                    fontWeight:
                                                      itemparent.option ===
                                                      "optionC"
                                                        ? "700"
                                                        : "",
                                                  }}
                                                >
                                                  {itemparent.optionC}
                                                </span>
                                              </Label>
                                            </FormGroup>
                                            <br />
                                            <FormGroup check>
                                              <Label check>
                                                <Input
                                                  disabled="disabled"
                                                  type="radio"
                                                  name="radio"
                                                  value="optionD"
                                                  checked={
                                                    itemparent.option ===
                                                    "optionD"
                                                  }
                                                  onChange={this.handleChange}
                                                />{" "}
                                                <span
                                                  style={{
                                                    fontWeight:
                                                      itemparent.option ===
                                                      "optionD"
                                                        ? "700"
                                                        : "",
                                                  }}
                                                >
                                                  {itemparent.optionD}
                                                </span>
                                              </Label>
                                            </FormGroup>
                                            <br />
                                            <p>Regards,</p>
                                            <p>
                                              {localStorage.getItem("uname1")}{" "}
                                              {localStorage.getItem("uname3")}
                                            </p>
                                          </Col>
                                        </FormGroup>
                                      </Form>
                                    </Container>
                                  </div>
                                ) : null}
                              </div>

                              <div className="message-body mt-5">
                                <div className="sender-details">
                                  <img
                                    className="img-xs rounded-circle mr-3"
                                    src={avatar}
                                    alt=""
                                  />
                                  <div className="details">
                                    <p className="msg-subject">
                                      Subject: {itemparent.questionTitle}
                                    </p>
                                    <p className="sender-email">
                                      From: {itemparent.reply_name} &nbsp;
                                      <a>{itemparent.reply_mail}</a>
                                    </p>
                                    <p className="receiver-email">
                                      To: {user} {second} &nbsp;
                                      <a>{localStorage.getItem("mail")}</a>
                                    </p>

                                    {itemparent.cc_mail !== "" && (
                                      <p className="receiver-email">
                                        Cc: &nbsp;
                                        <a>{itemparent.cc_mail}</a>
                                      </p>
                                    )}

                                    {itemparent.sent == 0 && (
                                      <a
                                        href="#"
                                        onClick={this.sentShow.bind(
                                          this,
                                          items
                                        )}
                                      >
                                        {" "}
                                        <p
                                          className="receiver-email"
                                          style={{
                                            color: "red",
                                            fontSize: "1.0rem",
                                            fontWeight: "700",
                                          }}
                                        >
                                          This mail has been replied
                                        </p>
                                      </a>
                                    )}

                                    {itemparent.draft == 0 && (
                                      <a>
                                        {" "}
                                        <p
                                          className="receiver-email"
                                          style={{
                                            color: "red",
                                            fontSize: "1.0rem",
                                            fontWeight: "700",
                                          }}
                                        >
                                          This mail is in draft stage
                                        </p>
                                      </a>
                                    )}
                                  </div>
                                </div>

                                <div className="message-content">
                                  {/* <p>{salutation}</p>  */}
                                  <p>Dear {user},</p>
                                  <p>{itemparent.question}</p>
                                  <p>
                                    <br />
                                    Regards,
                                    <br />
                                    {itemparent.reply_name}
                                  </p>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Mail;
