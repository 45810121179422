import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
// import "./_style.scss";
import '../email/email.css';
import'../../App.css';
import logo from '../../zayogro.png';
// import AuthService from "../components/details/AuthService";
import axios from'axios';
// import CookieService from "./CookieService";
import { Col, Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import UrlService from "../details/UrlService";


class Details extends Component<RouteComponentProps> {
    state = {
        firstname: "",
        middlename: "",
        lastname: "",
        company: "",
        gender: "",
        department: "",
        age: "",
        totalexp: "",
        currentexp: "",
        testpur: "",
        isChecked: false,
    };
   

    async handleFormSubmit(event) {

        event.preventDefault();

        const postData = {
            // firstname: this.state.firstname,
            // middlename: this.state.middlename,
            // lastname: this.state.lastname,
            company: this.state.company,
            gender: this.state.gender,
            department: this.state.department,
            age: this.state.age,
            totalexp: this.state.totalexp,
            currentexp: this.state.currentexp,
            testpur: this.state.testpur,
        };

        const usid = localStorage.getItem('userid');
        this.props.history.push("/Details");
        // axios.put('http://localhost:8000/api/formSubmit/'+usid, postData)
        // .then(function (response) {

        //     console.log(response.data);

        // })

        // .catch(function (error) {

        //     console.log(error);

        // });
        
        

        
        // const response = await AuthService.doUserLogin(postData);
        // if (response) {
        //     AuthService.handleLoginSuccess(response, this.state.isChecked);
        //     this.props.history.push("/dashboard");
        // } else {
        //     alert("Please check your credentials and try again");
        // }
    }

    pdfpodt()
    {
        const usid = localStorage.getItem('userid');
        const testid = localStorage.getItem('testid');
        axios.post(UrlService.PdfUrl(usid),{ 
            params: {
            id:usid,
            tid:testid}})
    }

    handleChecked() {
        this.setState({ isChecked: !this.state.isChecked });
    }

    render() {
        const { firstname, middlename, lastname, company,gender,department,age,totalexp,currentexp,testpur} = this.state;
        const usid = localStorage.getItem('userid');
        return (
            <React.Fragment>
<div className="container-fluid" style={{ backgroundColor: "#FFFFFF" }}>
        {/* <nav className="navbar navbar-light bg-primary"><img src={logo} className="login-logo-small mt-1 h-vmin"/><p style={{fontWeight:"700",fontSize:"1.5rem"}}>Evolve</p></nav> */}
<nav className="navbar navbar-light  mt-0 "  >
    <img src={logo} className="login-logo-small mt-1 h-5vmin" alt=""></img>
    <p style={{fontWeight:"700",fontSize:"1.7rem",color:"white",textShadow: "4px 4px 8px #000000"}}>E-Volve</p>
    </nav>
    <div className="content-wrapper">
        <div className="email-wrapper wrapper">
            <div className="row align-items-stretch">
              <div className="mail-sidebar d-none d-lg-block col-md-2 pt-3 bg-white">
                   
              </div>

                    <div className="mail-view d-none d-md-block col-md-9  bg-white mt-5">

                        <p>Thank you {localStorage.getItem('uname1')}</p>
                        <p>

                        Congratulations for successfully completing the E-Volve process.</p>
                        <p> Hope you enjoyed it.
                         We will shortly get back to you with the next steps. </p>
                         <p>Thank You. Please close the browser window.</p>  
                         {/* <Button type="button"
                                    className="btn btn-primary" onClick={this.pdfpodt}>Open Report</Button> */}
                         {/* <p><a href={"http://evolveadmin.zayogro.com/admin/company/pdf/"+localStorage.getItem('userid')} >Please Click here to Download the report.</a></p> */}
                         {/* <p><a href={"http://evolveadmin.zayogro.com/admin/company/pdf"} >Please Click here to Download the report.</a></p>      */}
                         {/* <p><a href={"http://localhost:8000/admin/company/pdf/"+localStorage.getItem('userid')+'/'+localStorage.getItem('test')} >Please Click here to Download the report.</a></p>                      */}
                        <p><a href={"http://evolveadmin.zayogro.com/admin/company/pdf/"+localStorage.getItem('userid')+'/'+localStorage.getItem('test')} target='blank' >Please Click here to View the report.</a></p>     

       
                    </div> 
              
            </div>
        </div>
    </div>
</div>
            </React.Fragment>
        );
    }
}

export default Details;
