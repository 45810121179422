import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
// import "./_style.scss";
import '../email/email.css';
import'../../App.css';
import logo from '../../zayogro.png';
// import AuthService from "../components/details/AuthService";
import axios from'axios';
// import CookieService from "./CookieService";
import { Col, Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';


class Details extends Component<RouteComponentProps> {
    state = {
        firstname: "",
        middlename: "",
        lastname: "",
        company: "",
        gender: "",
        department: "",
        age: "",
        totalexp: "",
        currentexp: "",
        testpur: "",
        isChecked: false,
    };
   

    async handleFormSubmit(event) {

        event.preventDefault();

        const postData = {
            // firstname: this.state.firstname,
            // middlename: this.state.middlename,
            // lastname: this.state.lastname,
            company: this.state.company,
            gender: this.state.gender,
            department: this.state.department,
            age: this.state.age,
            totalexp: this.state.totalexp,
            currentexp: this.state.currentexp,
            testpur: this.state.testpur,
        };

        const usid = localStorage.getItem('userid');
        this.props.history.push("/Details");
        // axios.put('http://localhost:8000/api/formSubmit/'+usid, postData)
        // .then(function (response) {

        //     console.log(response.data);

        // })

        // .catch(function (error) {

        //     console.log(error);

        // });
        
        

        
        // const response = await AuthService.doUserLogin(postData);
        // if (response) {
        //     AuthService.handleLoginSuccess(response, this.state.isChecked);
        //     this.props.history.push("/dashboard");
        // } else {
        //     alert("Please check your credentials and try again");
        // }
    }

    handleChecked() {
        this.setState({ isChecked: !this.state.isChecked });
    }

    render() {
        const { firstname, middlename, lastname, company,gender,department,age,totalexp,currentexp,testpur} = this.state;
        const usid = localStorage.getItem('userid');
        return (
            <React.Fragment>
<div class="container-fluid" style={{ backgroundColor: "#FFFFFF" }}>
{/* <nav className="navbar navbar-light bg-primary"><img src={logo} className="login-logo-small mt-1 h-vmin"/><p style={{fontWeight:"700",fontSize:"1.5rem"}}>Evolve</p></nav> */}
<nav className="navbar navbar-light  mt-0 ">
    <img src={logo} className="login-logo-small mt-1 h-5vmin" alt=""></img>
    <p style={{fontWeight:"700",fontSize:"1.7rem",color:"white",textShadow: "4px 4px 8px #000000"}}>E-Volve</p>
    </nav>
    <div class="content-wrapper">
        <div class="email-wrapper wrapper">
            <div class="row align-items-stretch">
              <div class="mail-sidebar d-none d-lg-block col-md-2 pt-3 bg-white">
                    <div class="menu-bar">
                            <ul class="menu-items">
                            {/* <img src={logo} className="login-logo-small mt-3"/> */}
                            <li><i class="mdi mdi-email-outline"></i> Introduction</li>
                                <li ><i class="mdi mdi-share"></i> Personal Details</li>
                                <li class="active"><i class="mdi mdi-upload"></i> Storyline</li>
                                <li><i class="mdi mdi-file-outline"></i> Mailbox</li>
                                <li><i class="mdi mdi-star-outline"></i> Thank You</li>
                                {/* <li><a href="#"><i class="mdi mdi-delete"></i> Trash</a></li> */}
                            </ul>                            
                    </div>
              </div>

                    <div class="mail-view d-none d-md-block col-md-9  bg-white mt-5" >

                        <p><b>A Day in the life of an Equity Sales Manager</b></p>

                        <p>During the assessment, you are adopting the role of the Associate Vice President – Equities for the fictitious company YUGOS. 
                            You have been recently promoted and transferred from the West region, where you were a Territory Manager.</p>

                        <p>In your previous role you were a star performer and widely credited with turning around the lagging numbers for your portfolio and turn around the West zone from #3 nationally to #1 for the last 4 quarters.
                             Now you have been asked to take charge of the overall Equities portfolio for the India and South Asia (ISA) region whose incumbent lead, Amitabh Singh, was sacked for poor performance last week.</p>

                        <p>You are being sent to replace Amitabh, who has resigned and left the organization. 
                            You are expected to handle the emerging concerns and further grow the business in this geography.
                             You have to meet your team and do work both internally and also in promoting the brands of YUGOS within the geography.</p>

                        <p>You are happy because the AVP Equities role is a promotion for you and to be given the responsibility to handle an entire ISA region is great learning opportunity and a stepping stone in your illustrious career. 
                            Though SA region just includes Singapore and Malaysia for now and is services by a small standalone team, you plan to push it aggressively as you see great opportunities there.</p>

                        <p>You understand that this is high visibility role and are prepared to overcome any challenges that will be there. However, the pressure of the last role did not really leave you with any time for family and you are starting to feel the strain.
                             You want to start with a fresh mind, which is why you had requested for a leave of 15 days to clear your thoughts and be with family before joining this role.</p>

                        <p>This is your first day in the office after the transfer from the West zone.
                             There are a number of decisions that need to be made and a range of outstanding issues that need to be addressed as you have received several mails in your in-box that have accumulated.</p>

                        <p>No responses, decisions or actions have been made on these items.
                             You have a total of approximately 15 minutes to understand the context, read through the mails and respond to the issues as you see fit.</p>

                        <p>In preparation for your first day in the office, your boss Anand Kumar (Sr. Dir – Markets and Securities) has pulled together a confidential set of documents to give you an overview of the business and allow you to hit the ground running.</p>

                        <p>The documents include information on YUGOS’s business; the company’s history; the organizational structure and division; the core strategies and the products produced, which range from simple to complex. 
                            Additionally there is information on business performance; competitor information; and detailed financial information along with new snippets of developments in the business environment.</p>

                        <p>Equity Sales Head (ISA region), YUGOS: PARTICIPANT ROLE DESCRIPTION</p>

                        <p>The organization has recently undergone some restructuring and Equity Sales Managers now have forecasting responsibilities for specific businesses as part of the management portfolio in addition to sales and service/distribution.
                            The intentions behind the restructuring were to help provide specialized focus on different businesses / product lines and enhance efficiencies.
                            To make this happen, they have also been assigned a Research and Analyst team reporting to them and advising them and their clients on the portfolio. 
                            The responsibilities of the role include:</p>

                        <p>•	Sales Planning, Primary Sales & Distribution responsibility, Region Management, and Customer Relationship Management for Mutual Fund clients and HNI in the ISA region.</p>
                        <p>•	Channel management, visibility programs in the geography.</p>
                        <p>•	Market forecasting and planning for the aligned to regional sales strategies.</p>
                        <p>•	Guidance and direction to on-roll and off-roll sales team.</p>
                        <p>•	Recruit, hire, and develop talent within the Region.</p>
                        <p>•	Create opportunities for business to grow market share in the short, medium and long Term.</p>
                        <p>•	Manage the geography to support current operations and performance as well as provide foundation for long-term growth prospects.</p>

                        <p>TEAMS AND REPORTING RELATIONSHIPS:</p>

                        <p>•	Reports to Anand Kumar – Sr. Dir – Markets and Securities</p>

                        <p>Direct Reports:</p>
                        <p>•	Territory Managers (Deepak Kumar, Vivek Singh, Kuldeep Raj, and Dona Negi) who are responsible for managing clients across the different regions in India through desk traders and client managers.</p>
                        <p>•	SA Sales Manager (David Ganesh) who is responsible for South Asian region (currently only Singapore and Malaysia).</p>
                        <p>•	Market Research and Analytics (Sreeram V.) who is responsible for studying and recommending stocks basis deep market data analytics and modelling.</p>
                        <p>•	HRBP (Jayesh Singh) who is the liaison between the operations and the HR function</p>

                        <p>More detail on the organization structure will be available to you when you dive deep into the company details.</p>
                        <p>Once you are through with this background, please click on the “Load” button to start the process.</p>
                        <p>Congratulations and wish you all the very best as you proceed.</p>
                        <p>ZayoGro Team. </p>


                        <div className="row">
                            {/* <div className="col-8">

                            </div> */}
                            <div className="col-2">
                                <button
                                    type="button"
                                    className="btn btn-primary btn-block"
                                        onClick={()=>{this.props.history.push("/mail1")}}
                                >
                                    Open Mailbox
                                </button>
                            </div>
                        </div>                          

                    </div> 
              
            </div>
        </div>
    </div>
</div>
            </React.Fragment>
        );
    }
}

export default Details;
